import { mapDataListToOptions } from '~/shared/mappers/selectOption';
import { Project } from '~/shared/models/api/project';
import {
  SelectOption,
  SelectOptionMapperKeysType,
} from '~/shared/models/react-select/select-option';
import { useEmployeeProjectAllocations } from '~/shared/queries/useGetEmployeeProjectAllocations';
import React, { FC, useEffect, useState } from 'react';
import Select from 'react-select';

interface ProjectSelectProps {
  id?: string;
  placeholder?: string;
  selectedProjects?: Project[];
  defaultValue?: Project;
  isDisabled?: boolean;
  isClearable?: boolean;
  employee_id: number;
  onChange?: (project_id?: number) => void;
}

const ProjectSelect: FC<ProjectSelectProps> = ({
  id = 'Project-select',
  placeholder,
  selectedProjects,
  isDisabled,
  isClearable = true,
  onChange,
  employee_id,
}) => {
  const [selected, setSelected] = useState<SelectOption<Project> | null>(null);
  const [options, setOptions] = useState<SelectOption<Project>[]>();
  const { data: employeeProjectAllocations, isLoading } =
    useEmployeeProjectAllocations(employee_id);
  const keys: SelectOptionMapperKeysType<Project> = {
    valueKey: 'id',
    labelKey: 'sms_title',
  };

  useEffect(() => {
    if (!employeeProjectAllocations) {
      return;
    }
    const selectOptions = mapDataListToOptions<Project>({
      options: employeeProjectAllocations.map((proj) => {
        return proj.project;
      }),
      ...keys,
    });
    setOptions(selectOptions);
  }, [employeeProjectAllocations]);

  return (
    <Select
      id={id}
      placeholder={placeholder}
      isLoading={isLoading}
      isClearable={isClearable}
      isDisabled={isDisabled}
      value={selected}
      options={options}
      isOptionDisabled={(value) =>
        selectedProjects?.some((project) => project.id === value.origin?.id) ||
        false
      }
      onChange={(value) => {
        onChange && onChange(value?.origin?.id);
        setSelected(value);
      }}
    />
  );
};

export default ProjectSelect;
