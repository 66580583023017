import { useToast } from '@chakra-ui/react';
import { setProjectChildren } from '~/shared/api/request/projects';
import { getErrorMessage } from '~/shared/errors/error';
import { ProjectChildrenProps } from '~/shared/interfaces/props';
import { Project } from '~/shared/models/api/project';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationResult } from 'react-query';

interface UseSetProjectChildren {
  setProjectChildren: UseMutationResult<
    Project,
    unknown,
    ProjectChildrenProps,
    unknown
  >;
}

export const useSetProjectChildren = (): UseSetProjectChildren => {
  const toast = useToast();
  const { t } = useTranslation('projects');

  return {
    setProjectChildren: useMutation<Project, AxiosError, ProjectChildrenProps>(
      (props: ProjectChildrenProps) => setProjectChildren({ ...props }),
      {
        onSuccess: ({ sms_title }: Project) => {
          toast({
            title: sms_title,
            description: t(`set_children.success`),
            status: 'success',
          });
        },
        onError: (err: AxiosError) => {
          toast({
            title: t(`set_children.fail`),
            description: getErrorMessage(
              err.response?.status,
              t(`something_wrong`),
              t
            ),
            status: 'error',
          });
        },
      }
    ),
  };
};
