import {
  Button,
  Grid,
  GridItem,
  ModalBody,
  ModalFooter,
  Stack,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  defaultNewExperiencesSchema,
  InternalExperiencesFormData,
  newExperiencesSchema,
} from '~/profile/forms/internalExperiences';
import useProfileStore from '~/profile/stores/profile';
import FormControl from '~/shared/components/form/FormControl';
import FormControlDatePicker from '~/shared/components/form/FormControlDatePicker';
import FormControlRichText from '~/shared/components/form/FormControlRichText';
import JobTitleSelect from '~/shared/components/select/JobTitleSelect';
import ProjectSelect from '~/shared/components/select/ProjectSelect';
import SkillSelectByValue from '~/shared/components/select/skill/SkillSelectByValue';
import { DateType } from '~/shared/enums/date';
import { EmployeeInternalExperience } from '~/shared/models/api/employee';
import { formatDate } from '~/shared/utils/date';
import React, { useEffect } from 'react';
import {
  FieldNamesMarkedBoolean,
  FormProvider,
  useForm,
} from 'react-hook-form';

interface InternalExperiencesFormProps {
  experience?: EmployeeInternalExperience;
  onSubmit: (project: InternalExperiencesFormData) => void;
  onCancel: () => void;
  onChange: (
    dirtyFields: Partial<
      Readonly<FieldNamesMarkedBoolean<EmployeeInternalExperience>>
    >
  ) => void;
}

const InternalExperiencesForm: React.FC<InternalExperiencesFormProps> = ({
  experience,
  onSubmit,
  onChange,
  onCancel,
}) => {
  const { profile } = useProfileStore();
  const methods = useForm({
    resolver: yupResolver(newExperiencesSchema),
    defaultValues: defaultNewExperiencesSchema(profile, experience),
  });
  const {
    handleSubmit,
    register,
    watch,
    reset,
    control,
    setValue,
    formState: { errors, dirtyFields },
  } = methods;

  const startAt = watch('start_at');
  const endAt = watch('end_at');
  const technologies = watch('technologies');
  const tasks = watch('tasks');

  const today = new Date();
  const formValues = watch();

  useEffect(() => {
    onChange(
      dirtyFields as FieldNamesMarkedBoolean<EmployeeInternalExperience>
    );
  }, [formValues, dirtyFields]);

  useEffect(() => {
    reset();
  }, []);

  const handleFormSubmit = (formData: InternalExperiencesFormData) => {
    if (!formData.project_id) {
      return;
    }

    let experience: InternalExperiencesFormData = {
      role: formData.role,
      technologies: formData.technologies,
      start_at: formatDate(formData.start_at),
      tasks: formData.tasks,
      project_id: formData.project_id,
    };

    if (formData.id && formData.id !== -1) {
      experience = { ...experience, id: formData.id };
    }

    if (formData.end_at) {
      experience = {
        ...experience,
        end_at: formatDate(formData.end_at),
      };
    }

    onSubmit(experience);
  };

  return (
    <FormProvider {...methods}>
      <ModalBody>
        <Text fontSize="md" color="gray.600" fontWeight="bold" mb="2">
          {experience?.project ? 'Editing Experience' : 'Add New Experience'}
        </Text>
        <Grid templateColumns="repeat(2, 1fr)" gap="2" mt="4">
          <GridItem colSpan={2}>
            <FormControl
              id="project_id"
              control={control}
              error={errors.project_id}
            >
              {profile?.id && !experience?.project ? (
                <ProjectSelect employee_id={profile.id} />
              ) : null}
            </FormControl>
          </GridItem>
          <FormControl
            id="role"
            label="Roles"
            control={control}
            error={errors.role}
          >
            <JobTitleSelect />
          </FormControl>
          <GridItem colSpan={2}>
            <FormControl
              id="technologies"
              label="Skills"
              control={control}
              error={errors.technologies}
            >
              <SkillSelectByValue selectedSkills={technologies} isMulti />
            </FormControl>
          </GridItem>

          <GridItem colSpan={2}>
            <FormControlRichText
              id="tasks"
              name="Tasks and Responsibilities"
              value={tasks}
              register={register}
              setValue={setValue}
              placeholder="Tasks and responsibilities on the project"
              error={errors.tasks}
            />
          </GridItem>
        </Grid>
        <Stack direction="row" alignItems="start" align="stretch">
          <FormControlDatePicker
            id="start_at"
            name="Date Started"
            register={register}
            setValue={setValue}
            error={errors.start_at}
            selectedDate={startAt ? new Date(startAt) : null}
            maxDate={endAt ? new Date(endAt) : today}
            chosenDateType={DateType.YEAR_MONTH}
          />
          <FormControlDatePicker
            id="end_at"
            name="Date Ended"
            register={register}
            error={errors.end_at}
            setValue={setValue}
            selectedDate={endAt ? new Date(endAt) : null}
            disabled={startAt == undefined}
            minDate={startAt ? new Date(startAt) : undefined}
            maxDate={today}
            chosenDateType={DateType.YEAR_MONTH}
          />
        </Stack>
      </ModalBody>
      <ModalFooter justifyContent="space-between">
        <Button
          data-testid="modal-experiences-close-button"
          type="button"
          mr={2}
          onClick={onCancel}
        >
          Close
        </Button>
        <Button
          colorScheme="blue"
          type="submit"
          variant="solid"
          mr={4}
          data-testid="modal-experiences-save-button"
          onClick={handleSubmit(handleFormSubmit)}
        >
          {experience?.project ? 'Update' : 'Save'}
        </Button>
      </ModalFooter>
    </FormProvider>
  );
};

export default InternalExperiencesForm;
