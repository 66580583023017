import DatePicker from '../ui/DatePicker';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { DateType } from '~/shared/enums/date';
import React from 'react';
import {
  FieldError,
  FieldErrorsImpl,
  FieldValues,
  Merge,
  Path,
  PathValue,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';

interface FormControlDatePickerProps<T extends FieldValues> {
  id: Path<T>;
  name?: string;
  register: UseFormRegister<T>;
  setValue?: UseFormSetValue<T>;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl>;
  maxDate?: Date;
  minDate?: Date;
  selectedDate: Date | null;
  disabled?: boolean;
  placeholder?: string;
  chosenDateType: DateType;
}

// HACK: when the ReactDatePicker is disabled, the component has a stranger style
const FormControlDatePicker = <T extends FieldValues>({
  id,
  name,
  register,
  setValue,
  error,
  maxDate,
  minDate,
  selectedDate,
  disabled,
  placeholder,
  chosenDateType,
}: FormControlDatePickerProps<T>) => {
  const { onChange, ref, ...rest } = register(id);

  const handleOnChange = (value: Date | null) => {
    if (setValue) {
      const parsedValue =
        chosenDateType === DateType.YEAR
          ? value?.getFullYear()
          : value?.toISOString();

      setValue(id, parsedValue as PathValue<T, Path<T>>, { shouldDirty: true });

      return;
    }
    if (chosenDateType === DateType.YEAR) {
      return onChange({ target: { value: value?.getFullYear(), name: id } });
    }

    return onChange({ target: { value: value?.toISOString(), name: id } });
  };

  return (
    <FormControl
      id={id}
      isInvalid={Boolean(error)}
      mb="2"
      isDisabled={disabled}
    >
      {!!name && <FormLabel>{name}</FormLabel>}
      <DatePicker
        data-testid={`test-form-${id}`}
        id={id}
        {...rest}
        innerRef={ref}
        chosenDateType={chosenDateType}
        selectedDate={selectedDate}
        onChange={handleOnChange}
        maxDate={maxDate}
        minDate={minDate}
        isClearable
        isDisabled={disabled}
        placeholder={placeholder}
      />
      <FormErrorMessage data-testid="test-form-error-message">
        {!!error && (error.message as string)}
      </FormErrorMessage>
    </FormControl>
  );
};

export default FormControlDatePicker;
