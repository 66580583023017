import {
  Badge,
  Box,
  BoxProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import FormControlInput from '~/shared/components/form/FormControlInput';
import FormControlRichText from '~/shared/components/form/FormControlRichText';
import IndustriesSelect from '~/shared/components/IndustrySelect';
import ModalButton from '~/shared/components/ui/ModalButton';
import { Industry } from '~/shared/models/api/industry';
import { UpdateProject } from '~/shared/models/api/project';
import React, { FC, PropsWithChildren, useEffect } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { boolean, object, string } from 'yup';

const projectEditModalSchema = object().shape({
  sms_title: string().required(),
  description: string().required(),
  industry: object().required(),
  hide_name: boolean(),
});

const ProjectEditModalBox = (props: PropsWithChildren<BoxProps>) => {
  const { children, ...rest } = props;
  return (
    <Box mb={4} {...rest}>
      {children}
    </Box>
  );
};

interface ProjectEditModalProps {
  isUpdating: boolean;
  isOpen: boolean;
  project?: UpdateProject;
  onClose: () => void;
  onSubmit: (update: FieldValues) => void;
}

const ProjectEditModal: FC<ProjectEditModalProps> = ({
  isOpen,
  onClose,
  project,
  onSubmit,
  isUpdating,
}) => {
  const { t } = useTranslation('projects');
  const form = useForm({ resolver: yupResolver(projectEditModalSchema) });
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = form;

  useEffect(() => {
    if (project) {
      reset(project);
    }
  }, [project, reset]);

  const onFormClose = () => {
    reset();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...form}>
          <Stack direction="column">
            <ModalHeader data-testid="modal-industries-header" mt="2" mb="0">
              <Badge colorScheme="blue">Project</Badge>
              <Heading fontSize="2xl" fontWeight="bold" mt="2">
                Edit Details
              </Heading>
            </ModalHeader>
          </Stack>
          <ModalCloseButton />
          <ModalBody>
            <ProjectEditModalBox>
              <FormControlInput
                id="sms_title"
                name={t('headers.project_alias')}
                placeholder=""
                register={register}
                error={errors.sms_title}
              />
            </ProjectEditModalBox>
            <ProjectEditModalBox>
              <FormControl id="industry" isInvalid={Boolean(errors.industry)}>
                <FormLabel>{t('headers.industry')}</FormLabel>
                <IndustriesSelect
                  id="industry-select"
                  selected={project?.industry}
                  isCreatable
                  onChange={(industry: Industry | null) =>
                    setValue('industry', industry)
                  }
                />
                <FormErrorMessage data-testid="test-form-error-message">
                  {!!errors &&
                    errors.industry?.message &&
                    'industry is a required field'}
                </FormErrorMessage>
              </FormControl>
            </ProjectEditModalBox>
            <ProjectEditModalBox>
              <FormControlRichText
                id="description"
                name={t('headers.description')}
                value={watch('description')}
                register={register}
                setValue={setValue}
                placeholder=""
                error={errors.description}
              />
            </ProjectEditModalBox>
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <ModalButton
              data-testid="modal-industries-close-button"
              type="button"
              onClick={onFormClose}
            >
              Cancel
            </ModalButton>
            <ModalButton
              colorScheme="blue"
              type="submit"
              variant="solid"
              onClick={handleSubmit(onSubmit)}
              disabled={!isDirty}
              isUpdating={isUpdating}
            >
              Update
            </ModalButton>
          </ModalFooter>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
};
export default ProjectEditModal;
