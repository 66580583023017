import { Flex, HStack, Text } from '@chakra-ui/react';
import { ProjectAccordionTableHeadersProps } from '~/administration/sections/Projects';
import SortableIcons from '~/shared/components/ui/sortable-icons/SortableIcons';
import { Project } from '~/shared/models/api/project';
import {
  OrderDirection,
  SearchFiltersOrderBy,
} from '~/shared/models/search/filters/search';
import React from 'react';

interface ProjectAccordionParentHeaderProps {
  headers: ProjectAccordionTableHeadersProps<Project>[];
  onSort?: (sortParam: keyof Project) => void;
  filters?: SearchFiltersOrderBy<keyof Project>;
  isChildren?: boolean;
}

export const ProjectAccordionTableHeaders: React.FC<
  ProjectAccordionParentHeaderProps
> = ({ headers, onSort, filters, isChildren }) => (
  <>
    {headers
      .filter(({ hideOnChild }) => !(isChildren && hideOnChild))
      .map(({ width, childWidth, key, header, isSortable }) => (
        <Flex
          w={`${isChildren && childWidth ? childWidth : width}px`}
          minW={`${isChildren && childWidth ? childWidth : width}px`}
          key={key + '-header'}
          whiteSpace="nowrap"
          {...(isSortable &&
            onSort && {
              cursor: 'pointer',
              onClick: () => onSort(key),
            })}
        >
          <HStack>
            <Text>{header}</Text>
            {isSortable && filters && (
              <SortableIcons
                order={
                  filters.order_by == key ? filters.order : OrderDirection.NONE
                }
              />
            )}
          </HStack>
        </Flex>
      ))}
  </>
);
