import { initialFilters } from '~/shared/constants/search';
import { StatusTypes } from '~/shared/enums/status';
import { Certificate } from '~/shared/models/api/certificate';
import {
  SearchFilters,
  SearchFiltersOrderBy,
} from '~/shared/models/search/filters/search';

export type CertificatesOrderBy = keyof Pick<
  Certificate,
  'name' | 'created_at'
>;

// Pending BE implementation
export interface CertificatesSearchFilters
  extends SearchFilters,
    SearchFiltersOrderBy<CertificatesOrderBy> {
  status?: StatusTypes;
}

export const initialCertificatesSearchFilters: CertificatesSearchFilters = {
  ...initialFilters,
  status: StatusTypes.PENDING,
};
