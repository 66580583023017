import { Icon, Text, Tooltip, Wrap } from '@chakra-ui/react';
import React from 'react';
import { IconType } from 'react-icons';

const DEFAULT_BOX_SIZE = 5;
const DEFAULT_ICON_PADDING_TOP = '0';
const DEFAULT_TEXT = '-';
const DEFAULT_SPACING = 2;

export enum IconPosition {
  LEFT = 'left',
  RIGHT = 'right',
}

interface AboutIconProps {
  boxSize?: number;
  fontWeight?: string;
  fontSize?: string;
  icon?: IconType;
  iconColor?: string;
  iconPaddingTop?: string;
  iconPosition?: IconPosition;
  spacing?: number;
  testId?: string;
  text?: string;
  tipLabel?: string;
}

export const AboutItemIcon = ({
  boxSize = DEFAULT_BOX_SIZE,
  fontSize,
  fontWeight,
  icon,
  iconColor,
  iconPaddingTop = DEFAULT_ICON_PADDING_TOP,
  iconPosition = IconPosition.LEFT,
  spacing = DEFAULT_SPACING,
  testId,
  text = DEFAULT_TEXT,
  tipLabel,
}: AboutIconProps) => {
  const aboutIcon = (
    <Icon
      as={icon}
      boxSize={boxSize}
      aria-label={text}
      color={iconColor}
      pt={iconPaddingTop}
    />
  );

  return (
    <Tooltip label={tipLabel}>
      <Wrap spacing={spacing}>
        {iconPosition === IconPosition.LEFT && aboutIcon}
        <Text fontWeight={fontWeight} fontSize={fontSize} data-testid={testId}>
          {text}
        </Text>
        {iconPosition === IconPosition.RIGHT && aboutIcon}
      </Wrap>
    </Tooltip>
  );
};
