import Certifications from './certifications/Certifications';
import InternalExperiences from './experiences/internal/InternalExperiences';
import ShortDescription from './short-description/ShortDescription';
// import TrainingsPage from './trainings/TrainingsPage';
import { Box } from '@chakra-ui/react';
import About from '~/profile/components/about/About';
import Educations from '~/profile/components/education/Educations';
import ExternalExperiences from '~/profile/components/experiences/external/ExternalExperiences';
import Industries from '~/profile/components/industries/Industries';
import Skills from '~/profile/components/skills/Skills';
import ConfirmationModalContext from '~/shared/hooks/useConfirmationModalContext';
import LoadingContext from '~/shared/hooks/useLoadingContext';
import React, { ReactNode } from 'react';

const ProfileComponent = () => {
  return (
    <Providers>
      <Box data-testid="profile-component">
        <About />
        <ShortDescription />
        <Certifications />
        <Skills />
        <Educations />
        <Industries />
        <InternalExperiences />
        <ExternalExperiences />
        {/* Pending Approval */}
        {/* <TrainingsPage />*/}
      </Box>
    </Providers>
  );
};

export default ProfileComponent;

function Providers({ children }: { children: ReactNode }) {
  return (
    <LoadingContext>
      <ConfirmationModalContext>{children}</ConfirmationModalContext>
    </LoadingContext>
  );
}
