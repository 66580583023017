export enum StatusTypes {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export type StatusColorsType = {
  [key in StatusTypes]: string;
};

export const statusTypesColors: StatusColorsType = {
  [StatusTypes.PENDING]: 'orange.300',
  [StatusTypes.APPROVED]: 'green.400',
  [StatusTypes.REJECTED]: 'red.400',
};
