import { getProjectChildren } from '~/shared/api/request/projects';
import { QueriesTypes } from '~/shared/enums/queries';
import { Project } from '~/shared/models/api/project';
import { AxiosError } from 'axios';
import { useQuery, useQueryClient } from 'react-query';

export const useGetProjectChildren = (projectId?: number) => {
  const queryKey = [QueriesTypes.GET_PROJECT_CHILDREN, projectId];

  const projectChildrenQuery = useQuery<Project[], AxiosError>({
    queryKey,
    queryFn: ({ queryKey }) => getProjectChildren(Number(queryKey[1])),
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const queryClient = useQueryClient();

  const invalidate = () => queryClient.invalidateQueries(queryKey);

  return { ...projectChildrenQuery, invalidate };
};
