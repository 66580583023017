import CertificateUpsertContent from './content/CertificateUpsert';
import CertificateViewContent from './content/CertificateView';
import {
  Box,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { CertificateModalMode } from '~/administration/hooks/certificates/useCertificateModal';
import ModalButton from '~/shared/components/ui/ModalButton';
import { StatusTypes } from '~/shared/enums/status';
import { Certificate } from '~/shared/models/api/certificate';
import React, { FC } from 'react';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface CertificateBaseModalProps {
  certificate?: Certificate;
  mode: CertificateModalMode;
  isUpdating: boolean;
  onClose: () => void;
  changeViewMode: (mode: CertificateModalMode) => void;
  onDelete: () => void;
  onSubmit: (upsert: FieldValues) => void;
  updateStatus: (newStatus: StatusTypes) => void;
}

const CertificateBaseModal: FC<CertificateBaseModalProps> = ({
  certificate,
  changeViewMode,
  isUpdating,
  mode,
  onClose,
  onSubmit,
  onDelete,
  updateStatus,
}) => {
  const { t: tCertificates } = useTranslation('certificates');
  const { t: tBase } = useTranslation('base');

  return (
    <Modal isOpen onClose={onClose} closeOnOverlayClick={!isUpdating} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {certificate
            ? `${tBase('edit')} ${certificate.name}`
            : tCertificates('create')}
        </ModalHeader>
        <ModalCloseButton disabled={isUpdating} />
        <ModalBody>
          {certificate && mode === CertificateModalMode.VIEW ? (
            <CertificateViewContent
              certificate={certificate}
              isUpdating={isUpdating}
              onClose={onClose}
              updateStatus={updateStatus}
            />
          ) : (
            <CertificateUpsertContent
              certificate={certificate}
              isUpdating={isUpdating}
              mode={mode}
              onCancel={() =>
                certificate
                  ? changeViewMode(CertificateModalMode.VIEW)
                  : onClose()
              }
              onSubmit={onSubmit}
            />
          )}
        </ModalBody>
        {mode === CertificateModalMode.VIEW && (
          <>
            <Divider my={5} />
            <ModalFooter justifyContent="space-between">
              <ModalButton
                type="button"
                onClick={onClose}
                disabled={isUpdating}
              >
                {tBase('close')}
              </ModalButton>
              <Box>
                <ModalButton
                  type="button"
                  colorScheme="red"
                  onClick={onDelete}
                  disabled={isUpdating}
                  mr={2}
                >
                  {tBase('delete')}
                </ModalButton>
                <ModalButton
                  type="button"
                  colorScheme="blue"
                  onClick={() => changeViewMode(CertificateModalMode.UPDATE)}
                  disabled={isUpdating}
                >
                  {tBase('edit')}
                </ModalButton>
              </Box>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default CertificateBaseModal;
