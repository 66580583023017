import { getProjectPotentialChildren } from '~/shared/api/request/projects';
import { QueriesTypes } from '~/shared/enums/queries';
import { Project } from '~/shared/models/api/project';
import { AxiosError } from 'axios';
import { useQuery, useQueryClient } from 'react-query';

export const useGetProjectPotentialChildren = (
  projectId?: number,
  query = ''
) => {
  const queryKey = [
    QueriesTypes.GET_PROJECT_POTENTIAL_CHILDREN,
    projectId,
    query,
  ];

  const potentialChildrenQuery = useQuery<Project[], AxiosError>({
    queryKey,
    queryFn: ({ queryKey }) =>
      getProjectPotentialChildren(Number(queryKey[1]), String(queryKey[2])),
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const queryClient = useQueryClient();

  const invalidate = () => queryClient.invalidateQueries(queryKey);

  return { ...potentialChildrenQuery, invalidate };
};
