import { useDisclosure } from '@chakra-ui/react';
import useProjectUpdate from '~/shared/hooks/project/useProjectUpdate';
import { Project, UpdateProject } from '~/shared/models/api/project';
import { mapProjectToUpdate } from '~/shared/utils/mappers/project';
import { useState } from 'react';
import { FieldValues } from 'react-hook-form';

export interface UseProjectEditModalType {
  isUpdating: boolean;
  isOpen: boolean;
  projectUpdate: UpdateProject | undefined;
  updatedProject?: Project;
  openModal?: (project: Project) => void;
  onClose: () => void;
  onSubmit: (update: FieldValues) => void;
}

const useProjectEditModal = (): UseProjectEditModalType => {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const {
    isOpen,
    onOpen: onProjectUpsertModalOpen,
    onClose: onProjectUpsertModalClose,
  } = useDisclosure();
  const [projectUpdate, setProjectUpdate] = useState<UpdateProject>();
  const [updatedProject, setUpdatedProject] = useState<Project>();
  const {
    update: { mutateAsync: updateProject },
  } = useProjectUpdate();

  const openModal = (project: Project) => {
    const update: UpdateProject = mapProjectToUpdate(project);
    setProjectUpdate(update);
    onProjectUpsertModalOpen();
  };

  const onSubmit = (formValues: FieldValues) => {
    setIsUpdating(true);
    updateProject(
      {
        ...projectUpdate,
        ...(formValues as UpdateProject),
      },
      {
        onSuccess: (project: Project) => {
          setUpdatedProject(project);
          onClose();
        },
        onSettled: () => setIsUpdating(false),
      }
    );
  };

  const onClose = () => {
    setProjectUpdate(undefined);
    onProjectUpsertModalClose();
  };

  return {
    isUpdating,
    isOpen,
    projectUpdate,
    updatedProject,
    openModal,
    onClose,
    onSubmit,
  };
};

export default useProjectEditModal;
