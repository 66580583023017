import { Project, UpdateProject } from '~/shared/models/api/project';

const mapProjectToUpdate = ({
  id,
  sms_title,
  industry,
  client_name,
  show_client_name,
  description,
}: Project): UpdateProject => {
  return {
    id,
    sms_title,
    industry,
    client_name,
    show_client_name,
    description,
  };
};

export { mapProjectToUpdate };
