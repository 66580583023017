import { ProfileSectionHeader } from '../../ProfileSectionHeader';
import InternalExperienceItem from './InternalExperienceItem';
import { Box, useDisclosure } from '@chakra-ui/react';
import EditModal from '~/profile/components/experiences/internal/EditModal';
import useProfileStore from '~/profile/stores/profile';
import MissingData from '~/shared/components/ui/MissingData';
import useInternalExperience from '~/shared/hooks/employee/useEmployeeInternalExperiences';
import { useConfirmationModalContext } from '~/shared/hooks/useConfirmationModalContext';
import { useLoadingContext } from '~/shared/hooks/useLoadingContext';
import { EmployeeInternalExperience } from '~/shared/models/api/employee';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const InternalExperiences: React.FC = () => {
  const { profile, updateEmployeeProfile } = useProfileStore();
  const { t } = useTranslation(['base', 'profile']);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedExperience, setSelectedExperience] =
    useState<EmployeeInternalExperience>();
  const { openConfirmation } = useConfirmationModalContext();
  const { startLoading, stopLoading } = useLoadingContext();
  const { deleteInternalExperience } = useInternalExperience();

  const openModal = (experience?: EmployeeInternalExperience) => {
    setSelectedExperience(experience);
    onOpen();
  };

  const closeModal = () => {
    setSelectedExperience(undefined);
    onClose();
  };

  const confirmDelete = async (experience: EmployeeInternalExperience) => {
    const result = await openConfirmation({
      isDangerAction: true,
      titleText: experience.project.title,
      descriptionText: t(`base:delete_confirmation`),
      confirmText: t(`base:delete`),
    });
    if (!result) {
      return;
    }
    deleteExperience(experience);
  };

  const deleteExperience = async (experience: EmployeeInternalExperience) => {
    if (!profile) {
      return;
    }
    startLoading();

    await deleteInternalExperience.mutateAsync(experience);
    updateEmployeeProfile({
      ki_projects: profile.ki_projects.filter(({ id }) => id !== experience.id),
    });
    stopLoading();
  };

  return (
    <Box data-testid="profile-component-experiences">
      {isOpen && (
        <EditModal experience={selectedExperience} onClose={closeModal} />
      )}
      <ProfileSectionHeader
        headingDataTestId="test-heading-experiences"
        sectionTitle={t(`base:company_projects`)}
        onClickAdd={openModal}
      />
      {!profile?.ki_projects?.length ? (
        <MissingData text={t(`profile:project.not_added`)} />
      ) : (
        <Box mt="4" borderRadius="md">
          {profile?.ki_projects?.map((ki_project) => (
            <InternalExperienceItem
              onOpen={() => openModal(ki_project)}
              onDelete={() => confirmDelete(ki_project)}
              key={`project${ki_project.project.id}`}
              experience={ki_project}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default InternalExperiences;
