import { Heading, Text } from '@chakra-ui/react';
import React from 'react';

export interface ProfileSectionItemTitleAndSubTitleProps {
  headingTestId?: string;
  title: string;
  atTitle?: string;
}

export const ProfileSectionItemTitleAndSubTitle = ({
  headingTestId,
  title,
  atTitle,
}: ProfileSectionItemTitleAndSubTitleProps) => {
  return (
    <Heading color="gray.600" data-testid={headingTestId} fontSize="md">
      <Text as="span">{title}</Text>
      {atTitle && (
        <>
          <Text as="span" ml={2} mr={1} color="gray.400">
            @
          </Text>
          <Text as="span">{atTitle}</Text>
        </>
      )}
    </Heading>
  );
};
