import {
  createEmployeeCertification,
  deleteEmployeeCertification,
} from '~/shared/api/request/certifications';
import {
  getEmployee,
  updateEmployee,
  updateEmployeeImage,
} from '~/shared/api/request/employee';
import { EndpointPaths } from '~/shared/enums/endpointPaths';
import {
  CreateEmployeeCertification,
  Employee,
  EmployeeCertificationBase,
  EmployeeEducation,
  EmployeeExternalExperience,
  EmployeeIndustry,
  EmployeeInternalExperience,
  EmployeeSkill,
  EmployeeTraining,
} from '~/shared/models/api/employee';
import { EmployeeIdOrMe } from '~/shared/types/employeeIdOrMe';
import { create } from 'zustand';

interface State {
  // Current/Logged user
  myProfile: Employee | null;
  // can be myProfile or profile by id if userId in url
  profile: Employee | null;
  isSelf: boolean;
  isLoadingMyProfile: boolean;
  setProfile: (employee: Employee | null) => void;
  setIsSelf: (value: boolean) => void;
  loadMyProfile: (force?: boolean) => void;
  updateProfilePicture: (image: Blob, imageBase64: string) => Promise<boolean>;
  updatePersonalData: (values: Employee) => Promise<boolean>;
  updateSkills: (values: EmployeeSkill[]) => Promise<boolean>;
  updateIndustries: (values: EmployeeIndustry[]) => Promise<boolean>;
  updateExperiences: (values: EmployeeInternalExperience[]) => Promise<boolean>;
  updateExternalExperiences: (
    values: EmployeeExternalExperience[]
  ) => Promise<boolean>;
  updateTrainings: (values: EmployeeTraining[]) => Promise<boolean>;
  updateProfile: (values: Partial<Employee>) => Promise<boolean>;
  updateEmployeeProfile: (values: Partial<Employee>) => void;
  createCertification: (
    values: CreateEmployeeCertification
  ) => Promise<boolean>;
  deleteCertification: (values: EmployeeCertificationBase) => Promise<boolean>;
  createEducation: (values: EmployeeEducation) => Promise<boolean>;
  updateEducation: (values: EmployeeEducation) => Promise<boolean>;
  deleteEducation: (value: EmployeeEducation) => Promise<boolean>;
  handleProfileUpdated: () => void;
  getProfileId: () => EmployeeIdOrMe;
  error?: Error | unknown;
}

const useProfileStore = create<State>((set, get) => ({
  myProfile: null,
  profile: null,
  isSelf: false,
  isLoadingMyProfile: true,
  errorMessages: [],

  setProfile: (employee) => {
    set({ profile: employee });
  },

  setIsSelf: (value: boolean) => {
    set({ isSelf: value });
  },

  getProfileId: () => {
    const { isSelf, profile } = get();

    return isSelf || !profile ? EndpointPaths.ME : profile.id;
  },

  loadMyProfile: async (force = false) => {
    const { isLoadingMyProfile, myProfile } = get();

    if ((isLoadingMyProfile || myProfile) && !force) {
      return;
    }

    set({ isLoadingMyProfile: true });

    try {
      const employee = await getEmployee(EndpointPaths.ME);

      set({ myProfile: employee });
    } catch (error) {
      set({ error });
    } finally {
      set({ isLoadingMyProfile: false });
    }
  },

  updateProfilePicture: async (nextImage: Blob) => {
    const { profile, getProfileId, loadMyProfile } = get();

    if (!profile) return false;

    try {
      await updateEmployeeImage(getProfileId(), {
        image: nextImage,
        email: profile.email,
      });

      loadMyProfile(true);
      return true;
    } catch (e) {
      return false;
    }
  },

  updatePersonalData: async ({
    seniority,
    job_title,
    social_links,
    bio,
    work_since,
    languages,
  }: Employee) => {
    const { updateProfile } = get();

    return updateProfile({
      seniority,
      job_title,
      social_links,
      bio,
      work_since,
      languages,
    });
  },

  updateSkills: async (values: EmployeeSkill[]) => {
    const { updateProfile } = get();

    return updateProfile({ main_skills: [...values] });
  },

  updateIndustries: async (values: EmployeeIndustry[]) => {
    const { updateProfile } = get();

    return updateProfile({ industries: [...values] });
  },

  updateExperiences: async (values) => {
    const { updateProfile } = get();

    return updateProfile({ ki_projects: [...values] });
  },

  updateExternalExperiences: async (values) => {
    const { updateProfile } = get();

    return updateProfile({ experiences_outside_ki: [...values] });
  },

  updateTrainings: async (values) => {
    const { updateProfile } = get();

    return updateProfile({ trainings: [...values] });
  },

  createCertification: async (values) => {
    const { getProfileId, profile, handleProfileUpdated } = get();

    if (!profile) return false;

    try {
      const newCertification = await createEmployeeCertification({
        ...values,
        employee_id: getProfileId(),
      });

      set({
        profile: {
          ...profile,
          certifications: [...profile.certifications, newCertification],
        },
      });
      handleProfileUpdated();
      return true;
    } catch (e) {
      return false;
    }
  },

  deleteCertification: async (values) => {
    const { getProfileId, profile, handleProfileUpdated } = get();

    if (!profile) return false;

    try {
      await deleteEmployeeCertification({
        ...values,
        employee_id: getProfileId(),
      });

      set({
        profile: {
          ...profile,
          certifications: profile.certifications.filter(
            (certification) =>
              certification.certificate_id != values.certificate_id
          ),
        },
      });
      handleProfileUpdated();
      return true;
    } catch (e) {
      return false;
    }
  },

  createEducation: async (value: EmployeeEducation) => {
    const { profile, updateProfile } = get();

    if (!profile) return false;

    return updateProfile({
      education: [...profile.education, value],
    });
  },

  updateEducation: async (value: EmployeeEducation) => {
    const { profile, updateProfile } = get();

    if (!profile) return false;

    return updateProfile({
      education: profile.education.map((p) =>
        p.education_id == value.education_id ? value : p
      ),
    });
  },

  deleteEducation: async (value: EmployeeEducation) => {
    const { profile, updateProfile } = get();

    if (!profile) return false;

    return updateProfile({
      education: profile.education.filter(
        (e) => e.education_id != value.education_id
      ),
    });
  },

  handleProfileUpdated: () => {
    const { profile, myProfile } = get();

    if (profile?.id == myProfile?.id) {
      set({ myProfile: profile });
    }
  },

  updateProfile: async (values) => {
    const { profile, getProfileId, handleProfileUpdated } = get();

    if (!profile) {
      return false;
    }

    try {
      const updated = await updateEmployee(getProfileId(), {
        ...values,
      });

      set({ profile: updated });

      handleProfileUpdated();

      return true;
    } catch (e) {
      return false;
    }
  },

  updateEmployeeProfile: async (values) => {
    const { profile, handleProfileUpdated } = get();

    if (!profile) {
      return false;
    }

    set({ profile: { ...profile, ...values } });

    handleProfileUpdated();
  },
}));

export default useProfileStore;
