import { Box, Heading } from '@chakra-ui/react';
import ReadOnlyRichText from '~/shared/components/ui/ReadOnlyRichText';
import { t } from 'i18next';
import React from 'react';

interface InternalExperienceItemDetailProps {
  title: string;
  text: string;
}

export const InternalExperienceItemDetail: React.FC<
  InternalExperienceItemDetailProps
> = ({ text, title }) => (
  <Box width="48%" backgroundColor="gray.50" rounded={10} px={2} py={1}>
    <Heading as="h4" fontSize="sm">
      {t(title)}
    </Heading>
    <ReadOnlyRichText value={text} />
  </Box>
);
