import 'react-datepicker/dist/react-datepicker.css';
import './date-picker.css';
import { DateType } from '~/shared/enums/date';
import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { UseFormRegisterReturn } from 'react-hook-form';

interface DatePickerProps {
  selectedDate: Date | null;
  maxDate?: Date | null;
  minDate?: Date | null;
  onChange: (date: Date) => void;
  isDisabled?: boolean;
  isClearable?: boolean;
  id?: string;
  innerRef?: UseFormRegisterReturn['ref'];
  placeholder?: string;
  chosenDateType: DateType;
}

const DatePicker: React.FC<DatePickerProps> = (props) => {
  const {
    selectedDate,
    isDisabled,
    innerRef,
    placeholder,
    chosenDateType,
    ...rest
  } = props;

  let showMonthYearPicker = false;
  let showYearPicker = false;

  /* Updates the value of the constants in order to validate if a month or a year picker
  should be used */
  const getTypeOfPicker = (value: string) => {
    if (value === DateType.YEAR) {
      showYearPicker = true;
    }

    if (value === DateType.YEAR_MONTH) {
      showMonthYearPicker = true;
    }
  };

  getTypeOfPicker(chosenDateType);

  return (
    <ReactDatePicker
      selected={isDisabled ? null : selectedDate}
      showPopperArrow={false}
      dateFormat={chosenDateType}
      showMonthYearPicker={showMonthYearPicker}
      showYearPicker={showYearPicker}
      disabled={isDisabled}
      placeholderText={placeholder || 'YYYY/MM'}
      ref={innerRef}
      {...rest}
    />
  );
};

export default DatePicker;
