import { PPTLayouts } from './enums';
import {
  addKiGroupYellowBoxes,
  addLine,
  createMasterSlideColumn,
} from './helper';
import { addTitlePlaceholder } from './placeholders';
import { PPTLayout } from './types';

export const getPPTLayout = (layout: PPTLayouts): PPTLayout => {
  const layouts = {
    oneProfilePerPage: {
      masterSlideTitle: 'KI_GROUP_ONE_PROFILE_MASTER_SLIDE',
      sectionTitle: 'Profiles',
      theme: { headFontFace: 'Montserrat', bodyFontFace: 'Montserrat' },
      objects: [
        ...addKiGroupYellowBoxes(),
        addTitlePlaceholder({ x: 0.72, y: 0.65, w: 5.85, h: 0.86 }),
        ...createMasterSlideColumn({ column: 0, rightMarginIncrement: 0 }),
      ],
    },
    twoProfilesPerPage: {
      masterSlideTitle: 'KI_GROUP_TWO_PROFILES_PER_PAGE_MASTER_SLIDE',
      sectionTitle: 'Profiles',
      theme: { headFontFace: 'Montserrat', bodyFontFace: 'Montserrat' },
      objects: [
        addTitlePlaceholder({ x: 0.72, y: 0.65, w: 11.85, h: 0.86 }),
        ...createMasterSlideColumn({ column: 0, rightMarginIncrement: 0 }),
        addLine({ x: 6.75, y: 1.87, w: 0, h: 4.66 }),
        ...createMasterSlideColumn({ column: 1, rightMarginIncrement: 6.3 }),
      ],
    },
  };

  const layoutSelected = layouts[layout];

  if (layoutSelected) {
    return layoutSelected;
  }

  throw new Error('Invalid PPT Layout');
};
