import AdvancedFiltersElement from '~/administration/components/advanced-filters/filters/AdvancedFiltersElement';
import AdvancedFiltersElementsContainer from '~/administration/components/advanced-filters/filters/AdvancedFiltersElementsContainer';
import useAdvancedFiltersForm from '~/administration/hooks/filters/useAdvancedFiltersForm';
import IndustriesSelect from '~/shared/components/IndustrySelect';
import ProjectStatusSelect from '~/shared/components/ProjectStatusSelect';
import DatePicker from '~/shared/components/ui/DatePicker';
import InputGroupWithClearable from '~/shared/components/ui/InputGroupWithClearable';
import { DateType } from '~/shared/enums/date';
import { ProjectsSearchFilters } from '~/shared/models/search/filters/projects';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type SearchFilters = Pick<
  ProjectsSearchFilters,
  'start_date' | 'end_date' | 'project_state' | 'industry' | 'client_name'
>;

interface ProjectsSearchAdvancedFiltersProps {
  filters: SearchFilters;
  showAdvanced: boolean;
  onFilterChange: (partialFilters: Partial<SearchFilters>) => void;
}

const ProjectsSearchAdvancedFilters: FC<ProjectsSearchAdvancedFiltersProps> = ({
  filters: { start_date, end_date, project_state, industry, client_name },
  showAdvanced,
  onFilterChange,
}) => {
  const { t } = useTranslation('base');
  const { values, setFieldValue } = useAdvancedFiltersForm<SearchFilters>();

  useEffect(() => {
    onFilterChange(values);
  }, [values]);

  return (
    <AdvancedFiltersElementsContainer showAdvanced={showAdvanced}>
      <AdvancedFiltersElement label={t('date.start_date')}>
        <DatePicker
          placeholder={t('select')}
          chosenDateType={DateType.YEAR_MONTH}
          isClearable={true}
          maxDate={end_date}
          selectedDate={start_date || null}
          onChange={(date) => setFieldValue('start_date', date)}
        />
      </AdvancedFiltersElement>

      <AdvancedFiltersElement label={t('date.end_date')}>
        <DatePicker
          placeholder={t('select')}
          chosenDateType={DateType.YEAR_MONTH}
          isClearable={true}
          minDate={start_date}
          selectedDate={end_date || null}
          onChange={(date) => setFieldValue('end_date', date)}
        />
      </AdvancedFiltersElement>
      <AdvancedFiltersElement label={t('state')}>
        <ProjectStatusSelect
          status={project_state}
          onChange={(st) => setFieldValue('project_state', st)}
        />
      </AdvancedFiltersElement>
      <AdvancedFiltersElement label={t('industry')}>
        <IndustriesSelect
          selected={industry}
          onChange={(ind) => setFieldValue('industry', ind)}
        />
      </AdvancedFiltersElement>
      <AdvancedFiltersElement label={t('client')}>
        <InputGroupWithClearable
          placeholder={t('search')}
          value={client_name}
          onChange={(cl) => setFieldValue('client_name', cl)}
        />
      </AdvancedFiltersElement>
    </AdvancedFiltersElementsContainer>
  );
};

export default ProjectsSearchAdvancedFilters;
