import { PPTLayouts } from './ppt/enums';
import {
  addMasterSlide,
  addMetadata,
  addSection,
  addSlide,
  fillSlideData,
} from './ppt/helper';
import { getPPTLayout } from './ppt/pptLayouts';
import { Employee } from '~/shared/models/api/employee';
import PptxGenJS from 'pptxgenjs';

const pptExport = async (
  layout: PPTLayouts,
  profiles: Employee[]
): Promise<void> => {
  if (!profiles.length) {
    return;
  }

  const pptx = new PptxGenJS();
  const pptTitle = `Profiles`;
  const layoutSelected = getPPTLayout(layout);

  addMetadata(pptx, layoutSelected, pptTitle);
  addMasterSlide(pptx, layoutSelected.masterSlideTitle, layoutSelected.objects);
  addSection(pptx, layoutSelected);

  const slide = addSlide(pptx, layoutSelected);

  for (let i = 0; i < profiles.length; i++) {
    const column = i % 2 === 0 ? 0 : 1;

    await fillSlideData(slide, column, profiles[i]);
  }

  pptx.writeFile({
    fileName: `${new Date().toISOString()}-${pptTitle}.pptx`,
  });
};

export default pptExport;
