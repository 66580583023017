import { useDisclosure, UseDisclosureReturn, useToast } from '@chakra-ui/react';
import useProjectEditModal, {
  UseProjectEditModalType,
} from '~/administration/hooks/projects/useProjectEditModal';
import { initialFilters } from '~/shared/constants/search';
import useSearchFilters, {
  UseSearchFiltersType,
} from '~/shared/hooks/filters/useSearchFilters';
import { Project } from '~/shared/models/api/project';
import {
  ProjectsOrderBy,
  ProjectsSearchFilters,
} from '~/shared/models/search/filters/projects';
import { OrderDirection } from '~/shared/models/search/filters/search';
import { PaginatedResponse } from '~/shared/models/search/paginated-response';
import { useGetProjectChildren } from '~/shared/queries/useGetProjectChildren';
import { useProjectsSearch } from '~/shared/queries/useProjectsSearch';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from 'react-query';

export interface UseProjects {
  searchFilters: UseSearchFiltersType<ProjectsSearchFilters>;
  handleOpenProjectMapModal: (id: number, title: string) => void;
  childrenProjects: UseQueryResult<Project[], AxiosError<unknown, unknown>>;
  parentProjects: UseQueryResult<
    PaginatedResponse<Project>,
    AxiosError<unknown, unknown>
  >;
  projectMapModal: UseDisclosureReturn;
  handleExpandAccordion: (expandedIndex: number | number[]) => void;
  handleEditProjectClick: (project: Project) => void;
  editProjectModal: UseProjectEditModalType;
  onSort: (sortParam: keyof Project) => void;
  projectId?: number;
  title?: string;
}

const useProjects = (): UseProjects => {
  const [projectId, setProjectId] = useState<number>();
  const [accordionIndex, setAccordionIndex] = useState<number>();
  const [title, setTitle] = useState<string>('');

  const toast = useToast();

  const { t } = useTranslation(['base', 'projects']);
  const projectMapModal = useDisclosure();

  const searchFilters = useSearchFilters<ProjectsSearchFilters>(initialFilters);

  const setSelectedProject = (id: number, title: string) => {
    setProjectId(id);
    setTitle(title);
  };

  const childrenProjects = useGetProjectChildren(projectId);

  const parentProjects = useProjectsSearch(searchFilters.filters);

  const handleOpenProjectMapModal = (id: number, title: string) => {
    setSelectedProject(id, title);

    projectMapModal.onOpen();
  };

  const editProjectModal = useProjectEditModal();

  const handleEditProjectClick = useCallback(
    (project: Project) => {
      if (editProjectModal.openModal && project) {
        editProjectModal.openModal(project);
      }
    },
    [editProjectModal.openModal]
  );

  const getOrderDirection = useCallback(
    (sortParam: keyof Project) => {
      const isSameParam = searchFilters.filters.order_by == sortParam;

      if (isSameParam && searchFilters.filters.order === OrderDirection.NONE) {
        return OrderDirection.ASCENDING;
      }

      if (
        isSameParam &&
        searchFilters.filters.order === OrderDirection.ASCENDING
      ) {
        return OrderDirection.DESCENDING;
      }

      if (
        isSameParam &&
        searchFilters.filters.order === OrderDirection.DESCENDING
      ) {
        return OrderDirection.NONE;
      }

      return OrderDirection.ASCENDING;
    },
    [searchFilters.filters.order, searchFilters.filters.order_by]
  );

  const onSort = useCallback(
    (sortParam: keyof Project) => {
      searchFilters.onFilterChange({
        order: getOrderDirection(sortParam),
        order_by: sortParam as ProjectsOrderBy,
      });
    },
    [getOrderDirection, searchFilters.onFilterChange]
  );

  const handleExpandAccordion = (expandedIndex: number | number[]) => {
    if (typeof expandedIndex === 'number') {
      setAccordionIndex(expandedIndex);
    }
  };

  useEffect(() => {
    if (childrenProjects.error) {
      toast({
        title: t('base:something_went_wrong'),
        description: t(`projects:fail_loading_children`),
        status: 'error',
      });
    }
  }, [childrenProjects.error]);

  useEffect(() => {
    if (!editProjectModal.updatedProject) {
      return;
    }
    parentProjects.refetch();
  }, [editProjectModal.updatedProject]);

  useEffect(() => {
    if (accordionIndex === undefined) {
      return;
    }

    const selectedProject = parentProjects.data?.page_results[accordionIndex];

    if (selectedProject) {
      setProjectId(selectedProject.id);
      setTitle(selectedProject.title);
    }
  }, [accordionIndex]);

  return {
    searchFilters,
    childrenProjects,
    parentProjects,
    projectMapModal,
    handleOpenProjectMapModal,
    handleExpandAccordion,
    handleEditProjectClick,
    editProjectModal,
    onSort,
    projectId,
    title,
  };
};

export default useProjects;
