import { Center, Flex, Spinner } from '@chakra-ui/react';
import { ProjectAccordionTableHeaders } from '~/administration/components/projects/ProjectAccordionTableHeaders';
import { ProjectAccordionTableItem } from '~/administration/components/projects/ProjectAccordionTableItem';
import { ProjectAccordionTableHeadersProps } from '~/administration/sections/Projects';
import { ActionButtons } from '~/profile/components/shared/ActionButtons';
import AlertInfo from '~/shared/components/ui/AlertInfo';
import MissingData from '~/shared/components/ui/MissingData';
import { Project } from '~/shared/models/api/project';
import { AxiosError } from 'axios';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ProjectChildrenListProps {
  headers: ProjectAccordionTableHeadersProps<Project>[];
  title?: string;
  projectChildren?: Project[];
  isLoading: boolean;
  error: AxiosError<unknown, unknown> | null;
  handleEditProjectClick: (project: Project) => void;
}

const ProjectChildrenList: React.FC<ProjectChildrenListProps> = ({
  headers,
  title,
  projectChildren,
  isLoading,
  error,
  handleEditProjectClick,
}) => {
  const { t } = useTranslation('projects');

  if (isLoading) {
    return (
      <Center>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Center>
    );
  }

  if (error) {
    return <AlertInfo text={t(`fail_loading_children`)} />;
  }

  if (!projectChildren?.length) {
    return <MissingData text={t(`no_children`)} />;
  }

  return (
    <>
      <Flex w="fit-content" gap={2}>
        <ProjectAccordionTableHeaders headers={headers} isChildren={true} />
        <Flex w="80px"></Flex>
      </Flex>
      {projectChildren?.map((child, childIndex) => (
        <Flex
          alignItems="center"
          bg={childIndex % 2 === 0 ? 'gray.50' : 'gray.100'}
          fontSize="sm"
          gap={2}
          key={child.id}
          py={1}
          rounded={8}
          w="min-content"
        >
          <ProjectAccordionTableItem
            headers={headers}
            item={child}
            isChildren={true}
          />
          <Flex w="80px">
            <ActionButtons
              tooltipLabel={title}
              onClickEdit={() => handleEditProjectClick(child)}
            />
          </Flex>
        </Flex>
      ))}
    </>
  );
};

export default ProjectChildrenList;
