import { AddIcon } from '@chakra-ui/icons';
import { IconButton, Stack, Text, Tooltip } from '@chakra-ui/react';
import { Child } from '~/administration/hooks/projects/useProjectMapModal';
import LoadingData from '~/shared/components/ui/LoadingData';
import MissingData from '~/shared/components/ui/MissingData';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface PotentialChildrenListProps {
  isLoading: boolean;
  data?: Child[];
  onAdd: (child: Child) => void;
}

const PotentialChildrenList: React.FC<PotentialChildrenListProps> = ({
  isLoading,
  data,
  onAdd,
}) => {
  const { t } = useTranslation('projects');

  if (isLoading) {
    return <LoadingData mt="5vh" />;
  }

  if (!data?.length) {
    return <MissingData text={t(`map_modal.parent_project_not_found`)} />;
  }

  return (
    <>
      {data.map(({ title, id }) => (
        <Stack key={`project-search-${id}`} m={0} p={0}>
          <Tooltip rounded={8} label={title}>
            <Text
              fontSize="xs"
              p={1}
              rounded={8}
              bg="orange.100"
              isTruncated
              shadow="sm"
              onClick={() => onAdd({ id, title })}
              mx={4}
            >
              <IconButton
                mt={1}
                aria-label={title}
                alignContent="center"
                color="blue.600"
                fontSize="sm"
                icon={<AddIcon />}
                variant="link"
              />
              {title}
            </Text>
          </Tooltip>
        </Stack>
      ))}
    </>
  );
};

export default PotentialChildrenList;
