import { Flex, Text, Tooltip } from '@chakra-ui/react';
import { ProjectAccordionTableHeadersProps } from '~/administration/sections/Projects';
import { Project } from '~/shared/models/api/project';
import React from 'react';

interface ProjectAccordionTableItemProps {
  headers: ProjectAccordionTableHeadersProps<Project>[];
  item: Project;
  isChildren?: boolean;
}

export const ProjectAccordionTableItem: React.FC<
  ProjectAccordionTableItemProps
> = ({ headers, item, isChildren }) => (
  <>
    {headers
      .filter(({ hideOnChild }) => !(isChildren && hideOnChild))
      .map(({ width, childWidth, key, component }) => (
        <Flex
          w={`${isChildren && childWidth ? childWidth : width}px`}
          minW={`${isChildren && childWidth ? childWidth : width}px`}
          key={`${key}-${item.id}`}
        >
          {component ? (
            component(item)
          ) : (
            <Tooltip rounded={8} label={item[key as keyof Project] as string}>
              <Text isTruncated>{item[key as keyof Project] as string}</Text>
            </Tooltip>
          )}
        </Flex>
      ))}
  </>
);
