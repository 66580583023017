import 'cropperjs/dist/cropper.css';
import ModalButton from './ui/ModalButton';
import {
  Box,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import { useTranslation } from 'react-i18next';

interface CropperModalProps {
  isOpen: boolean;
  title?: string;
  onClose: () => void;
  image: string | undefined;
  onCroppedImage: (image: Blob | null, imageBase64: string | undefined) => void;
}

const CropperModal: React.FC<CropperModalProps> = ({
  isOpen,
  title,
  onClose,
  image,
  onCroppedImage,
}) => {
  const { t } = useTranslation('base');
  const cropperRef = useRef<ReactCropperElement>(null);
  const canvasOptions: Cropper.GetCroppedCanvasOptions = {
    width: 300,
    height: 300,
    imageSmoothingQuality: 'high',
    imageSmoothingEnabled: false,
  };

  const onCrop = () => {
    const imageElement: ReactCropperElement | null = cropperRef?.current;
    const cropper = imageElement?.cropper;
    const nextImageBase64 = cropper
      ?.getCroppedCanvas(canvasOptions)
      .toDataURL();

    cropper?.getCroppedCanvas(canvasOptions).toBlob((nextImage) => {
      onCroppedImage(nextImage, nextImageBase64);
    });

    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        {title && (
          <ModalHeader data-testid="test-modal-header">{title}</ModalHeader>
        )}
        <ModalCloseButton />
        <ModalBody paddingTop="14">
          <Box mb={7}>
            <Cropper
              src={image}
              style={{ height: '35vh', minHeight: '300px', width: 'auto' }}
              aspectRatio={1}
              guides={false}
              size={300}
              ref={cropperRef}
              preview=".cropper-preview"
            />
          </Box>
          <Box textAlign="center">
            <Text
              size="md"
              width="300px"
              margin="auto"
              backgroundColor="gray.400"
              color="white"
              p={1}
              letterSpacing={2}
            >
              {t('preview').toUpperCase()}
            </Text>
            <Box
              className="cropper-preview"
              height="35vh"
              minHeight="300px"
              width="300px"
              overflow="hidden"
              margin="auto"
            />
          </Box>
        </ModalBody>
        <ModalFooter justifyContent="center" pt={6}>
          <HStack spacing={6}>
            <ModalButton type="button" mr={3} onClick={onClose}>
              {t('cancel')}
            </ModalButton>
            <ModalButton type="submit" colorScheme="blue" onClick={onCrop}>
              {t('save')}
            </ModalButton>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CropperModal;
