import CertificateTag from '../components/verify-certificates/CertificateTag';
import VerifyCertificatesSearchFilters from '../components/verify-certificates/filters/VerifyCertificatesSearchFilters';
import VerifyCertificateModal from '../components/verify-certificates/modal/VerifyCertificateModal';
import { Tbody, Wrap, WrapItem } from '@chakra-ui/react';
import EmployeesTable from '~/administration/components/employees-search-table/EmployeesSearchTable';
import EmployeesRow from '~/administration/components/employees-search-table/EmployeesSearchTableRow';
import EmployeesThead from '~/administration/components/employees-search-table/EmployeesSearchTableThead';
import useVerifyCertificateModal from '~/administration/hooks/verify-certificates/useVerifyCertificateModal';
import { EmployeeTableKeys } from '~/administration/models/employee';
import { Permission } from '~/shared/enums/permissions';
import { StatusTypes } from '~/shared/enums/status';
import useSearchFilters from '~/shared/hooks/filters/useSearchFilters';
import useRouteGuard from '~/shared/hooks/permissions/useRouteGuard';
import { Employee, EmployeeCertification } from '~/shared/models/api/employee';
import {
  EmployeeFilters,
  initialEmployeeFilters,
} from '~/shared/models/search/filters/employees';
import { useEmployeesSearch } from '~/shared/queries/useEmployeesSearch';
import React, { useEffect, useState } from 'react';

const keys: EmployeeTableKeys = ['name', 'company'];

const VerifyCertificates: React.FC = () => {
  useRouteGuard([Permission.VERIFY_CERTIFICATES]);

  const {
    filters,
    onPageChange,
    onFilterChange,
    onQueryChange,
    isFilterChanged,
    resetFilters,
    showAdvanced,
    toggleShowAdvanced,
  } = useSearchFilters<EmployeeFilters>({
    ...initialEmployeeFilters,
    certification_status: StatusTypes.PENDING,
  });
  const employeesSearchProps = useEmployeesSearch(filters);

  const { data, isFetching, error } = employeesSearchProps;
  const {
    certificateWasUpdated,
    isOpen,
    isUpdatingDate,
    isUpdatingStatus,
    isUploadingFile,
    onClose,
    onDateChange,
    onFileChange,
    onStatusChange,
    openModal,
    updateCertification,
  } = useVerifyCertificateModal();
  const [employees, setEmployees] = useState<Employee[]>();

  useEffect(() => {
    setEmployees(data?.page_results);
  }, [data]);

  useEffect(() => {
    if (!certificateWasUpdated || !updateCertification) {
      return;
    }
    updateEmployeeCertification(updateCertification);
  }, [certificateWasUpdated, updateCertification]);

  const updateEmployeeCertification = (
    certification: EmployeeCertification
  ) => {
    const update = employees?.map((employee) => {
      if (employee.id !== certification.employee_id) {
        return employee;
      }
      const certifications = employee.certifications?.map((cert) => {
        if (cert.certificate_id !== certification.certificate_id) return cert;
        return certification;
      });
      return { ...employee, certifications };
    });
    setEmployees(update);
  };

  return (
    <>
      {updateCertification && (
        <VerifyCertificateModal
          certification={updateCertification}
          onFileChange={onFileChange}
          isOpen={isOpen}
          isUpdatingDate={isUpdatingDate}
          isUpdatingStatus={isUpdatingStatus}
          isUploadingFile={isUploadingFile}
          onClose={onClose}
          onDateChange={onDateChange}
          onStatusChange={onStatusChange}
        />
      )}
      <VerifyCertificatesSearchFilters
        filters={filters}
        showAdvanced={showAdvanced}
        isFilterChanged={isFilterChanged}
        onFilterChange={onFilterChange}
        onQueryChange={onQueryChange}
        toggleShowAdvanced={toggleShowAdvanced}
        resetFilters={resetFilters}
      />
      <EmployeesTable
        filters={filters}
        isFetching={isFetching}
        data={data}
        error={error}
        onPageChange={onPageChange}
      >
        <EmployeesThead keys={[...keys, 'certifications']} />
        <Tbody>
          {employees?.map((employee) => {
            return (
              <EmployeesRow
                employee={employee}
                keys={keys}
                key={`row-${employee.id}`}
              >
                <Wrap spacing={3}>
                  {employee.certifications?.map((employeeCertificate) => {
                    return (
                      <WrapItem
                        key={`certificate-tag-${employeeCertificate.certificate_id}`}
                      >
                        <CertificateTag
                          certificate={employeeCertificate}
                          onClick={() => openModal(employeeCertificate)}
                        />
                      </WrapItem>
                    );
                  })}
                </Wrap>
              </EmployeesRow>
            );
          })}
        </Tbody>
      </EmployeesTable>
    </>
  );
};

export default VerifyCertificates;
