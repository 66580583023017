import { ProfileSectionItemHeader } from '../ProfileSectionItemHeader';
import { Box, Text } from '@chakra-ui/react';
import { EmployeeEducation } from '~/shared/models/api/employee';
import React from 'react';

interface EducationItemProps {
  education: EmployeeEducation;
  onDelete: () => void;
  onEdit: () => void;
}

const EducationItem: React.FC<EducationItemProps> = ({
  education,
  onDelete,
  onEdit,
}) => {
  return (
    <Box mb="8" borderRadius="md">
      <ProfileSectionItemHeader
        onClickEdit={() => onEdit()}
        onClickDelete={() => onDelete()}
        title={education.degree_name}
        dateIntervalTagTestId="education-dates"
        startDate={education.started}
        endDate={education.ended}
      />
      <Text fontSize="md" ml={8}>
        {`${education.education.study_field_name} - ${education.institution_name}`}
      </Text>
    </Box>
  );
};

export default EducationItem;
