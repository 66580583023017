import { colors } from './colors';
import { PlaceholderKeys } from './enums';
import { metadata } from './metadata';
import {
  addJobTitlePlaceholder,
  addNameSurnamePlaceholder,
  addProfilePicturePlaceholder,
  addSkillsPlaceholder,
  addTasksPlaceholder,
} from './placeholders';
import {
  BuildTextProps,
  CreateMasterSlideColumnProps,
  PPTLayout,
} from './types';
import { loadAzureBlob } from '~/shared/api/loadAzureBlob';
import {
  Employee,
  EmployeeExternalExperience,
  EmployeeInternalExperience,
  EmployeeSkill,
} from '~/shared/models/api/employee';
import PptxGenJS from 'pptxgenjs';

const sanitizeHtml = (text: string) =>
  text
    .replace(/\u200B/g, '') // Zero-width space
    .replace(/\u200C/g, '') // Zero-width non-joiner
    .replace(/\u200D/g, '') // Zero-width joiner
    .replace(/\uFEFF/g, '') // Zero-width no-break space
    .replace(/\u00A0/g, ' ') // Non-breaking space
    .replace(/\u2028/g, '') // Line separator
    .replace(/\u2029/g, '') // Paragraph separator
    .replace('\u000B', '') // Vertical Tab
    .replace('\t', '') // Tab
    .replace('\n', ''); // New Line

const replaceHtmlElements = (text: string) =>
  text
    .replace(/<a [^>]*href="([^"]+)"[^>]*>(.*?)<\/a>/gi, `"$2"($1)`) // Replace <a href> tags with the text and URL in parentheses
    .replace(/<ul>/gi, '\n') // Replace <ul> with a new line
    .replace(/<ol>/gi, '\n') // Replace <ol> with a new line
    .replace(/<\/ul>/gi, '') // Remove </ul>
    .replace(/<\/ol>/gi, '') // Remove </ol>
    .replace(/<li>/gi, '  - ') // Replace <li> with "  - "
    .replace(/<\/li>/gi, '\n') // Replace </li> with a new line
    .replace(/<br\s*\/?>/gi, '\n') // Replace <br> with a new line
    .replace(/<\/p>/gi, '\n') // Replace </p> with a new line
    .replace(/<\/?[^>]+(>|$)/g, '') // Remove all other HTML tags
    .replace(/\n\s*\n/g, '\n') // Remove extra blank lines
    .trim(); // Trim leading and trailing whitespace

export const stripHtml = (html: string): string => {
  const sanitizedHtml = sanitizeHtml(html);

  return replaceHtmlElements(sanitizedHtml);
};

export const buildInternalExperiences = (
  experiences: EmployeeInternalExperience[]
) => {
  if (!experiences.length) {
    return '';
  }

  try {
    return experiences.map((e) => stripHtml(e.tasks)).join('\n');
  } catch (error) {
    console.warn(`Fail generating KI Experiences: ${error}`);
    return 'Fail generating KI Experiences';
  }
};

export const buildExternalExperiences = (
  experiences: EmployeeExternalExperience[]
) => {
  if (!experiences.length) {
    return '';
  }

  try {
    return experiences.map((e) => stripHtml(e.description)).join('\n');
  } catch (error) {
    console.warn(`Fail generating Outside KI Experiences: ${error}`);

    return 'Fail generating Outside KI Experiences';
  }
};

export const buildSkillsText = (skills: EmployeeSkill[]): string =>
  skills
    .sort((a, b) => b.skill_level_id - a.skill_level_id)
    .map((s) => `${s.skill.name} ${s.skill_level_id}`)
    .join('\n');

export const addYellowBox = (
  horizontalPosition: number,
  verticalPosition: number
) => ({
  rect: {
    x: horizontalPosition,
    y: verticalPosition,
    w: 0.59,
    h: 0.59,
    fill: { color: colors.yellow },
  },
});

export const addKiGroupYellowBoxes = () => [
  addYellowBox(12.74, 0),
  addYellowBox(9.77, 0.59),
  addYellowBox(12.15, 0.59),
  addYellowBox(11.55, 1.19),
  addYellowBox(12.74, 1.19),
  addYellowBox(10.96, 2.77),
  addYellowBox(10.36, 3.36),
  addYellowBox(11.55, 4.51),
  addYellowBox(12.15, 6.31),
  addYellowBox(11.57, 6.91),
  addYellowBox(12.74, 6.91),
];

export const addWhiteBoxWithShadow = (
  positionProps: PptxGenJS.PositionProps
): { rect: PptxGenJS.ShapeProps } => ({
  rect: {
    ...positionProps,
    fill: {
      color: colors.white,
      transparency: 0,
      type: 'solid',
    },
    shadow: {
      type: 'outer',
      color: colors.boxShadow,
      opacity: 0.3,
      angle: 0,
      blur: 24,
      offset: 0,
    },
  },
});

export const addLine = (
  positionProps: PptxGenJS.PositionProps
): { line: PptxGenJS.ShapeProps } => ({
  line: {
    ...positionProps,
    line: {
      color: colors.gray,
      width: 1,
    },
  },
});

export const addKiGroupLogoBlack = () => {
  const path = '/company-logos/KIgroup_Logo_Black.png';

  return { image: { x: 0.84, y: 7.03, w: 0.93, h: 0.24, path } };
};

export const addDiamondIcon = (positionProps: PptxGenJS.PositionProps) => {
  const path = '/diamond-icon.svg';

  return { image: { ...positionProps, w: 0.17, h: 0.15, path } };
};

export const buildText = ({
  text,
  position,
  bold = false,
  color = colors.black,
  fontSize = 10,
}: BuildTextProps): { text: PptxGenJS.TextProps } => ({
  text: { text, options: { ...position, color, bold, fontSize } },
});

export const addText = (text: string, positionProps: PptxGenJS.PositionProps) =>
  buildText({ text, position: positionProps });

export const getPlaceholderName = (
  name: PlaceholderKeys,
  column: number
): string => `${name}Column${column}`;

export const createMasterSlideColumn = ({
  column,
  rightMarginIncrement,
}: CreateMasterSlideColumnProps) => [
  addWhiteBoxWithShadow({
    x: rightMarginIncrement + 0.72,
    y: 2.59,
    w: 3.61,
    h: 3.92,
  }),
  addWhiteBoxWithShadow({
    x: rightMarginIncrement + 4.45,
    y: 2.59,
    w: 2.03,
    h: 3.92,
  }),
  addDiamondIcon({ x: rightMarginIncrement + 4.65, y: 3.15 }),
  addText('Insights/Expertise', {
    x: rightMarginIncrement + 4.8,
    y: 3.15,
    w: 1.5,
    h: 0.17,
  }),
  addKiGroupLogoBlack(),
  addNameSurnamePlaceholder(column, {
    x: rightMarginIncrement + 0.8,
    y: 1.87,
    w: 3.52,
    h: 0.36,
  }),
  addJobTitlePlaceholder(column, {
    x: rightMarginIncrement + 0.8,
    y: 2.24,
    w: 3.52,
    h: 0.24,
  }),
  addTasksPlaceholder(column, {
    x: rightMarginIncrement + 0.8,
    y: 2.6,
    w: 3.52,
    h: 3.83,
  }),
  addProfilePicturePlaceholder(column, {
    x: rightMarginIncrement + 4.82,
    y: 1.8,
    w: 1.29,
    h: 1.29,
  }),
  addSkillsPlaceholder(column, {
    x: rightMarginIncrement + 4.5,
    y: 3.37,
    w: 2.15,
    h: 3.04,
  }),
];

export const addMasterSlide = (
  pptx: PptxGenJS,
  title: string,
  objects: PptxGenJS.SlideMasterProps['objects']
) => {
  pptx.defineSlideMaster({
    title,
    background: { color: colors.white },
    objects: objects,
  });
};

export const addMetadata = (
  pptx: PptxGenJS,
  layout: PPTLayout,
  title: string
) => {
  pptx.revision = '1';
  pptx.title = title;
  pptx.author = metadata.author;
  pptx.company = metadata.company;
  pptx.subject = metadata.subject;
  pptx.layout = metadata.layout;
  pptx.theme = layout.theme;
};

const buildDescription = (profile: Employee): string => {
  if (profile.short_description) {
    return profile.short_description;
  }

  const internalExperience = buildInternalExperiences(profile.ki_projects);
  const externalExperience = buildExternalExperiences(
    profile.experiences_outside_ki
  );

  return `${internalExperience}\n\n${externalExperience}`;
};

export const fillSlideData = async (
  slide: PptxGenJS.Slide,
  column: number,
  profile: Employee
) => {
  slide.addText(`${profile.first_name} ${profile.second_name}`, {
    placeholder: getPlaceholderName(PlaceholderKeys.nameSurname, column),
  });

  slide.addText(`${profile.seniority} ${profile.job_title}`, {
    placeholder: getPlaceholderName(PlaceholderKeys.jobTitle, column),
  });

  slide.addText(buildDescription(profile), {
    placeholder: getPlaceholderName(PlaceholderKeys.description, column),
  });

  slide.addText(buildSkillsText(profile.main_skills), {
    placeholder: getPlaceholderName(PlaceholderKeys.skills, column),
  });

  const avatarUrl = await loadAzureBlob(profile.image_url);

  slide.addImage({
    path: avatarUrl,
    placeholder: getPlaceholderName(PlaceholderKeys.profilePicture, column),
    rounding: true,
    sizing: { type: 'cover', w: 1.29, h: 1.29 },
  });
};

export const addSection = (pptx: PptxGenJS, layout: PPTLayout) => {
  pptx.addSection({ title: layout.sectionTitle });
};

export const addSlide = (pptx: PptxGenJS, layout: PPTLayout) =>
  pptx.addSlide({
    masterName: layout.masterSlideTitle,
    sectionTitle: layout.sectionTitle,
  });
