/* eslint-disable no-shadow */
export enum AdminRoutesPaths {
  PROJECTS = 'projects',
  PERMISSIONS = 'permissions',
  PERMISSION_GROUPS = 'permission-groups',
  SKILLS = 'skills',
  CERTIFICATES = 'certificates',
  VERIFY_CERTIFICATES = 'verify-certificates',
  VERIFY_PROFILES = 'verify-profiles',
  CLIENT_MAPPING = 'client-mapping',
}
