import { ProfileSectionHeader } from '../ProfileSectionHeader';
import { Box, useDisclosure, useToast } from '@chakra-ui/react';
import CertificationItem from '~/profile/components/certifications/CertificationItem';
import EditModal from '~/profile/components/certifications/EditModal';
import useProfileStore from '~/profile/stores/profile';
import MissingData from '~/shared/components/ui/MissingData';
import useAccessPermissions from '~/shared/hooks/permissions/useAccessPermissions';
import { useConfirmationModalContext } from '~/shared/hooks/useConfirmationModalContext';
import { useLoadingContext } from '~/shared/hooks/useLoadingContext';
import { EmployeeCertification } from '~/shared/models/api/employee';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const CertificationsPage = () => {
  const toast = useToast();
  const { profile, deleteCertification } = useProfileStore();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { openConfirmation } = useConfirmationModalContext();
  const { startLoading, stopLoading } = useLoadingContext();
  const { t } = useTranslation(['base', 'certificates']);
  const [certifications, setCertifications] =
    useState<EmployeeCertification[]>();
  const { canSeeCertification } = useAccessPermissions();

  useEffect(() => {
    if (!profile?.certifications.length) {
      setCertifications([]);
      return;
    }
    setCertifications(orderCertifications(profile.certifications));
  }, [profile?.certifications]);

  const orderCertifications = (list: EmployeeCertification[]) =>
    list.sort((a, b) => {
      // If both dates are null, order by adquired date
      if (!a.expiring_date && !b.expiring_date) {
        return b.adquired_date.localeCompare(a.adquired_date);
      }

      // If one date is null, the other one goes first
      if (!a.expiring_date) {
        return -1;
      }

      if (!b.expiring_date) {
        return 1;
      }

      return b.expiring_date.localeCompare(a.expiring_date);
    });

  const confirmDelete = async (certification: EmployeeCertification) => {
    const result = await openConfirmation({
      isDangerAction: true,
      titleText: certification.certificate.name,
      descriptionText: t(`base:delete_confirmation`),
      confirmText: t(`base:delete`),
    });

    if (result) {
      startLoading();
      const updateSuccess = await deleteCertification({
        ...certification,
        certificate_name: certification.certificate.name,
      });

      stopLoading();

      toast({
        title: t(`certificates:profile_certification_delete`),
        description: updateSuccess
          ? t(`certificates:certification_deleted`)
          : t(`base:something_went_wrong`),
        status: updateSuccess ? 'success' : 'error',
      });
    }
  };

  const visibleCertifications = certifications?.filter((c) =>
    canSeeCertification(c.status)
  );

  return (
    <Box data-testid="profile-component-certifications">
      {isOpen && <EditModal onClose={onClose} />}

      <ProfileSectionHeader
        headingDataTestId="test-certification-heading"
        sectionTitle={t(`certificates:certifications`)}
        onClickAdd={() => onOpen()}
      />

      {!visibleCertifications?.length ? (
        <MissingData text={t(`certificates:no_certifications_added`)} />
      ) : (
        <Box mt="4" borderRadius="md" data-testid="certifications-list">
          {visibleCertifications.map((certification) => (
            <CertificationItem
              key={`certification${certification.certificate_id}`}
              certification={certification}
              onDelete={() => confirmDelete(certification)}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default CertificationsPage;
