import { colors } from './colors';
import { PlaceholderKeys } from './enums';
import { getPlaceholderName } from './helper';
import { Placeholder, PlaceholderProps, Placeholders } from './types';
import PptxGenJS from 'pptxgenjs';

const getPlaceholderProps = (key: PlaceholderKeys): PlaceholderProps => {
  const placeholdersProps: Placeholders = {
    description: {
      name: PlaceholderKeys.description,
      text: 'Tasks Text',
      fontSize: 9,
    },

    jobTitle: {
      name: PlaceholderKeys.jobTitle,
      text: 'Job Title',
      color: colors.gray,
      fontSize: 11,
      bold: true,
    },

    nameSurname: {
      name: PlaceholderKeys.nameSurname,
      text: 'Name Surname',
      fontSize: 17,
      bold: true,
    },

    profilePicture: {
      name: PlaceholderKeys.profilePicture,
      type: 'pic' as PptxGenJS.PLACEHOLDER_TYPES,
      fontSize: 6,
    },

    skills: {
      name: PlaceholderKeys.skills,
      text: 'Skills Text',
      fontSize: 9,
      bullet: true,
    },

    title: {
      name: PlaceholderKeys.title,
      text: 'Title',
      fontSize: 24,
      bold: true,
    },
  };

  return placeholdersProps[key];
};

const buildPlaceholder = (
  column: number,
  {
    bold = false,
    bullet = false,
    color = colors.black,
    fontSize = 6,
    name,
    position,
    text,
    type = 'body' as PptxGenJS.PLACEHOLDER_TYPES,
  }: PlaceholderProps
): Placeholder => ({
  placeholder: {
    options: {
      ...position,
      align: 'left',
      bold,
      bullet,
      color,
      fontSize,
      name: getPlaceholderName(name, column),
      margin: 0,
      type,
    },
    text,
  },
});

const addPlaceholder = (
  column: number,
  placeholderPropsKey: PlaceholderKeys,
  position: PptxGenJS.PositionProps
) =>
  buildPlaceholder(column, {
    ...getPlaceholderProps(placeholderPropsKey),
    position,
  });

export const addTitlePlaceholder = (position: PptxGenJS.PositionProps) =>
  addPlaceholder(0, PlaceholderKeys.title, position);

export const addNameSurnamePlaceholder = (
  column: number,
  position: PptxGenJS.PositionProps
) => addPlaceholder(column, PlaceholderKeys.nameSurname, position);

export const addJobTitlePlaceholder = (
  column: number,
  position: PptxGenJS.PositionProps
) => addPlaceholder(column, PlaceholderKeys.jobTitle, position);

export const addTasksPlaceholder = (
  column: number,
  position: PptxGenJS.PositionProps
) => addPlaceholder(column, PlaceholderKeys.description, position);

export const addSkillsPlaceholder = (
  column: number,
  position: PptxGenJS.PositionProps
) => addPlaceholder(column, PlaceholderKeys.skills, position);

export const addProfilePicturePlaceholder = (
  column: number,
  position: PptxGenJS.PositionProps
) => addPlaceholder(column, PlaceholderKeys.profilePicture, position);
