import { Avatar, AvatarProps, Spinner } from '@chakra-ui/react';
import { useLoadAzureBlob } from '~/shared/queries/useLoadAzureBlob';
import React, { FC } from 'react';

interface EmployeeAvatarProps extends AvatarProps {
  imagePath?: string;
  name?: string;
}

const EmployeeAvatar: FC<EmployeeAvatarProps> = ({
  imagePath,
  name,
  ...avatarProps
}) => {
  const { data: avatarUrl, isFetching } = useLoadAzureBlob(imagePath);

  return (
    <Avatar
      size="lg"
      name={name}
      src={avatarUrl}
      bg="blackAlpha.500"
      color="white"
      {...(isFetching
        ? {
            icon: (
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="lg"
              />
            ),
          }
        : {})}
      {...avatarProps}
    />
  );
};

export default EmployeeAvatar;
