import { InternalExperiencesFormData } from '~/profile/forms/internalExperiences';
import { deleteData, patch, post } from '~/shared/api/endpoints';
import { EmployeeInternalExperience } from '~/shared/models/api/employee';
import { getInternalExperiencesEndpoint } from '~/shared/utils/requests';

export const createInternalExperience = async ({
  employee_id,
  ...payload
}: InternalExperiencesFormData): Promise<EmployeeInternalExperience> => {
  const { data } = await post(
    getInternalExperiencesEndpoint(employee_id).url,
    payload
  );

  return data;
};

export const deleteExternalExperience = async ({
  employee_id,
  id,
}: EmployeeInternalExperience): Promise<EmployeeInternalExperience> => {
  const { data } = await deleteData<EmployeeInternalExperience>(
    getInternalExperiencesEndpoint(employee_id).id(id).url
  );

  return data;
};

export const updateInternalExperience = async ({
  employee_id,
  id,
  ...payload
}: InternalExperiencesFormData): Promise<EmployeeInternalExperience> => {
  if (!id) {
    throw new Error('Experience Id must be defined');
  }
  if (!payload.project_id) {
    throw new Error('Project Id must be defined');
  }

  const { data } = await patch<EmployeeInternalExperience>(
    getInternalExperiencesEndpoint(employee_id).id(id).url,
    payload
  );
  return data;
};
