import { useToast } from '@chakra-ui/react';
import { updateProject } from '~/shared/api/request/projects';
import { getErrorMessage } from '~/shared/errors/error';
import { Project, UpdateProject } from '~/shared/models/api/project';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationResult } from 'react-query';

interface UseUpdateProjectType {
  update: UseMutationResult<Project, unknown, UpdateProject, unknown>;
}

const useProjectUpdate = (): UseUpdateProjectType => {
  const toast = useToast();
  const { t } = useTranslation('permissions');

  const update = useMutation(
    (projectUpdate: UpdateProject) => updateProject({ ...projectUpdate }),
    {
      onSuccess: (updatedProject: Project) => {
        toast({
          title: updatedProject.sms_title,
          description: 'Project Updated Successfully',
          status: 'success',
        });
      },
      onError: (err: AxiosError, projectUpdate: UpdateProject) => {
        toast({
          title: projectUpdate.sms_title || 'Error Updating Project',
          description: getErrorMessage(
            err.response?.status,
            'Something Went Wrong Updating Project',
            t
          ),
          status: 'error',
        });
      },
    }
  );

  return {
    update,
  };
};

export default useProjectUpdate;
