import { Th, Thead, Tr } from '@chakra-ui/react';
import { EmployeeTableKeys } from '~/administration/models/employee';
import React, { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

type EmployeesSearchTableTheadProps = {
  keys?: EmployeeTableKeys;
};

const EmployeesSearchTableThead: FC<
  PropsWithChildren<EmployeesSearchTableTheadProps>
> = ({ keys, children }) => {
  const { t } = useTranslation('employees');

  return (
    <Thead>
      <Tr>
        {keys?.map((key) => (
          <Th key={`header-${key}`} overflow="hidden" whiteSpace="nowrap">
            {t(`employee.${key}`)}
          </Th>
        ))}
        {children && children}
      </Tr>
    </Thead>
  );
};

export default EmployeesSearchTableThead;
