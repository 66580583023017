export enum QueriesTypes {
  FILTER_EMPLOYEES = 'FILTER_EMPLOYEES',

  GET_AZURE_BLOB_URL = 'GET_AZURE_BLOB_URL',
  GET_EMPLOYEE_PROJECT_ALLOCATIONS = 'GET_EMPLOYEE_PROJECT_ALLOCATIONS',
  GET_COMPANY_LOGO = 'GET_COMPANY_LOGO',
  GET_EMPLOYEE = 'GET_EMPLOYEE',
  GET_KI_COMPANIES = 'GET_KI_COMPANIES',
  GET_PERMISSIONS = 'GET_PERMISSIONS',
  GET_PROFILE_COMPANY_LOGO = 'GET_PROFILE_COMPANY_LOGO',
  GET_PROJECT_CHILDREN = 'GET_PROJECT_CHILDREN',
  GET_PROJECT_POSITIONS = 'GET_PROJECT_POSITIONS',
  GET_PROJECT_POTENTIAL_CHILDREN = 'GET_PROJECT_POTENTIAL_CHILDREN',
  GET_ROLES = 'GET_ROLES',
  GET_SKILLS_TYPES = 'GET_SKILLS_TYPES',
  GET_TEAMS = 'GET_TEAMS',

  SEARCH_CERTIFICATES = 'SEARCH_CERTIFICATES',
  SEARCH_CLIENTS = 'SEARCH_CLIENTS',
  SEARCH_EMPLOYEES = 'SEARCH_EMPLOYEES',
  SEARCH_INDUSTRIES = 'SEARCH_INDUSTRIES',
  SEARCH_LANGUAGES = 'SEARCH_LANGUAGES',
  SEARCH_PROJECTS = 'SEARCH_PROJECTS',
  SEARCH_SKILLS = 'SEARCH_SKILLS',
}
