import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import CompaniesRadio from '~/profile/components/export/CompaniesRadio';
import {
  defaultExportSchema,
  ExportFormType,
  exportSchema,
  mapExport,
} from '~/profile/forms/export';
import useProfileStore from '~/profile/stores/profile';
import { getEmployeeExport } from '~/shared/api/request/employee';
import FormControlCheckbox from '~/shared/components/form/FormControlCheckbox';
import FormControlMultipleSelectSync from '~/shared/components/form/FormControlMultipleSelectSync';
import { newDate } from '~/shared/utils/date';
import download from 'downloadjs';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

interface ExportModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ExportModal: React.FC<ExportModalProps> = ({ isOpen, onClose }) => {
  const toast = useToast();
  const { profile, getProfileId } = useProfileStore();

  const [loading, setLoading] = useState(false);

  const methods = useForm({
    resolver: yupResolver(exportSchema),
    defaultValues: defaultExportSchema(profile),
  });

  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
    watch,
  } = methods;

  useEffect(() => {
    if (!isOpen) return;
    reset(defaultExportSchema(profile));
  }, [isOpen]);

  const handleExportClick = async (values: ExportFormType) => {
    if (!values.personalData) return;

    setLoading(true);

    try {
      const mappedValues = mapExport(values);

      const pdfFile = await getEmployeeExport(mappedValues, getProfileId());

      download(
        pdfFile.data,
        `profile_${values.personalData.first_name}_${
          values.personalData.second_name
        }_${newDate('YYYY_MM_DD_HH_mm_ss')}.pdf`
      );

      toast({
        title: 'Successfully exported',
        description: 'Check your downloads folder',
        status: 'success',
      });

      setLoading(false);
      onClose();
      reset();
    } catch (e: unknown) {
      toast({
        title: "Couldn't export the profile to PDF",
        description: (e as Error).message || 'Unknown exception',
        status: 'error',
      });

      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <ModalHeader data-testid="export-modal-header">
            Profile exporting
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <CompaniesRadio
              id="company"
              error={errors.company}
              selected={watch('company')}
            />
            <Flex marginTop={10}>
              <FormControlMultipleSelectSync
                id="skills"
                name="Skills to highlight"
                register={register}
                error={errors.skills}
                options={profile?.main_skills?.map((skill) => ({
                  value: skill.skill.id,
                  label: skill.skill.name,
                }))}
              />
            </Flex>
            <Flex marginTop={10}>
              <FormControlMultipleSelectSync
                id="industries"
                name="Industries to highlight"
                register={register}
                error={errors.industries}
                options={profile?.industries?.map((industry) => ({
                  value: industry.industry?.id || industry.industry_id,
                  label: industry.industry?.name || '',
                }))}
              />
            </Flex>
            <Flex marginTop={10}>
              <FormControlCheckbox
                id="showProficiencies"
                name="Show proficiencies"
                error={errors.showProficiencies}
                register={register}
                checked={watch('showProficiencies')}
              />
            </Flex>
            {/* <Flex marginTop={10}>
              <FormControlCheckbox
                id="hideClientNames"
                name="Hide client names"
                error={errors.hideClientNames}
                register={register}
                checked={watch('hideClientNames')}
              />
            </Flex> */}
            <Flex marginTop={10}>
              <FormControlCheckbox
                id="isTech"
                name={watch('isTech') ? 'Tech Profile' : 'Business Profile'}
                error={errors.isTech}
                register={register}
                checked={watch('isTech')}
              />
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button type="button" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              type="submit"
              colorScheme="blue"
              onClick={handleSubmit(handleExportClick)}
              isLoading={loading}
            >
              PDF
            </Button>
          </ModalFooter>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
};

export default ExportModal;
