import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Center,
  Spinner,
} from '@chakra-ui/react';
import Pagination, {
  PaginationByFiltersProps,
} from '~/shared/components/PaginationByFilters';
import { AxiosError } from 'axios';
import React, { PropsWithChildren } from 'react';

type ErrorStateProps = {
  error: AxiosError | null;
};

const ErrorState = ({ error }: ErrorStateProps) => {
  return (
    <Alert status="error">
      <AlertIcon />
      <AlertTitle mr={2}>
        {error?.code && <>{error.code}</>}
        An error occured!
      </AlertTitle>
      <AlertDescription>Please contact us for assistance.</AlertDescription>
    </Alert>
  );
};

const EmptyState = () => {
  return (
    <Alert
      status="warning"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      height="200px"
    >
      <AlertIcon boxSize="40px" mr={0} />
      <AlertTitle mt={4} mb={1} fontSize="lg">
        No results were found!
      </AlertTitle>
      <AlertDescription maxWidth="sm">
        Please consider using a different search term.
      </AlertDescription>
    </Alert>
  );
};

type SearchResponseProps = {
  loading: boolean;
  error: AxiosError | null;
  paginationData?: PaginationByFiltersProps;
  noResults: boolean;
};

const SearchResponseContent: React.FC<
  PropsWithChildren<SearchResponseProps>
> = ({ loading, error, noResults, children, paginationData }) => {
  if (error) {
    return <ErrorState error={error} />;
  }

  if (loading) {
    return (
      <Center>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Center>
    );
  }

  if (noResults && !loading) {
    return <EmptyState />;
  }

  return (
    <>
      {children}

      {paginationData && <Pagination {...paginationData} />}
    </>
  );
};

export default SearchResponseContent;
