import EmployeesSearchTableCell from './EmployeesSearchTableCell';
import { Td, Tr } from '@chakra-ui/react';
import { EmployeeTableKeys } from '~/administration/models/employee';
import { Employee } from '~/shared/models/api/employee';
import React, { Children, FC, PropsWithChildren, ReactNode } from 'react';

type EmployeesSearchTableRowProps = {
  keys?: EmployeeTableKeys;
  nameLinkToProfile?: boolean;
  employee: Employee;
};

const EmployeesSearchTableRow: FC<
  PropsWithChildren<EmployeesSearchTableRowProps>
> = ({ keys, employee, nameLinkToProfile, children }) => {
  return (
    <Tr>
      {keys?.map((key) => (
        <EmployeesSearchTableCell
          employee={employee}
          employeeKey={key}
          nameLinkToProfile={nameLinkToProfile}
          key={`value-${key}`}
        />
      ))}
      {Children.map(children, (child: ReactNode) => (
        <Td>{child}</Td>
      ))}
    </Tr>
  );
};

export default EmployeesSearchTableRow;
