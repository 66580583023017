import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { IconButton, Text, Tooltip } from '@chakra-ui/react';
import { ChildRecord } from '~/administration/hooks/projects/useProjectMapModal';
import LoadingData from '~/shared/components/ui/LoadingData';
import MissingData from '~/shared/components/ui/MissingData';
import { ChildStatus, ChildStatusColor } from '~/shared/enums/childStatusColor';
import { wasAdded, wasRemoved } from '~/shared/utils/projects';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BiCheck, BiMinus, BiPlus } from 'react-icons/bi';

export interface ChildrenListProps {
  data: ChildRecord[];
  isLoading: boolean;
  onRemove: (index: number) => void;

  canChange?: boolean;
}

const ChildrenList: React.FC<ChildrenListProps> = ({
  canChange = true,
  data,
  isLoading,
  onRemove,
}) => {
  const { t } = useTranslation('projects');

  const getIcon = useCallback(
    (status: ChildStatus) => {
      if (canChange) {
        return wasRemoved(status) ? <AddIcon /> : <DeleteIcon />;
      }

      if (wasAdded(status)) {
        return <BiPlus />;
      }

      return wasRemoved(status) ? <BiMinus /> : <BiCheck />;
    },
    [wasAdded, wasRemoved]
  );

  const getColor = useCallback(
    (status: ChildStatus) => {
      if (
        (!wasRemoved(status) && canChange) ||
        (wasRemoved(status) && !canChange)
      ) {
        return 'red.400';
      }

      if (
        (wasAdded(status) && !canChange) ||
        (wasRemoved(status) && canChange)
      ) {
        return 'blue.600';
      }

      return 'gray.500';
    },
    [wasAdded, wasRemoved, canChange]
  );

  if (isLoading) {
    return <LoadingData mt="5vh" />;
  }

  if (!data.length) {
    return <MissingData text={t(`no_children`)} />;
  }

  return (
    <>
      {data.map(({ id, title, status }, index) => (
        <Tooltip key={`children-project-${id}`} rounded={8} label={title}>
          <Text
            fontSize="xs"
            mx={4}
            my={2}
            p={1}
            rounded={8}
            bg={ChildStatusColor[status]}
            isTruncated
            style={{
              textDecoration: wasRemoved(status) ? 'line-through' : 'none',
            }}
            shadow="sm"
            {...(canChange && { onClick: () => onRemove(index) })}
          >
            <IconButton
              mt={1}
              aria-label={title}
              alignContent="center"
              color={getColor(status)}
              fontSize="sm"
              icon={getIcon(status)}
              variant="link"
            />

            {title}
          </Text>
        </Tooltip>
      ))}
    </>
  );
};

export default ChildrenList;
