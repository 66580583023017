import { Flex, Spinner, useToast } from '@chakra-ui/react';
import useProfileStore from '~/profile/stores/profile';
import {
  createIndustry,
  searchIndustriesDebounce,
} from '~/shared/api/request/industries';
import { initialFilters } from '~/shared/constants/search';
import { Industry } from '~/shared/models/api/industry';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import AsyncSelectCreatable from 'react-select/async-creatable';

interface IndustriesSelectProps {
  id?: string;
  selected?: Industry | null;
  selectedName?: string | null;
  isCreatable?: boolean;
  placeholder?: string;
  onChange: (value: Industry | null) => void;
}

const IndustriesSelect: FC<IndustriesSelectProps> = ({
  id = 'industry-select',
  selected: defaultValue = null,
  isCreatable = false,
  placeholder = 'Select',
  onChange,
}) => {
  const toast = useToast();
  const { myProfile: profile } = useProfileStore();
  const [loading, setLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<Industry | null>(null);

  useEffect(() => {
    if (defaultValue?.id === selected?.id) {
      return;
    }
    setSelected(defaultValue);
  }, [defaultValue]);

  const searchIndustries = (query = '') =>
    searchIndustriesDebounce({
      ...initialFilters,
      query,
    });

  const handleChange = (value: Industry | null) => {
    setSelected(value);
    onChange(value);
  };

  const handleCreate = async (value: string) => {
    setLoading(true);
    if (!profile) {
      onErrorCreatingIndustry();
      return;
    }
    try {
      const created = await createIndustry({
        name: value,
        employee_id: profile.id,
      });

      if (!created) {
        onErrorCreatingIndustry('Server failed to give a valid response.');
        setLoading(false);
        return;
      }
    } catch (e) {
      onErrorCreatingIndustry();
      setLoading(false);
    }
  };

  const onErrorCreatingIndustry = (
    description = 'Something went wrong with the industry creation.'
  ) => {
    toast({
      title: 'Create Industry',
      description,
      status: 'error',
    });
  };

  if (loading) {
    return (
      <Flex justifyContent="center" w="full">
        <Spinner />
      </Flex>
    );
  }

  const selectProps = {
    id,
    value: selected,
    onChange: handleChange,
    getOptionLabel: (industry: Industry) => industry.name,
    getOptionValue: (industry: Industry) => industry.id.toString(),
    defaultOptions: true,
    loadOptions: searchIndustries,
    placeholder: placeholder,
    isClearable: true,
    cacheOptions: true,
  };
  return (
    <>
      {isCreatable ? (
        <AsyncSelectCreatable
          onCreateOption={handleCreate}
          isMulti={false}
          {...selectProps}
          getNewOptionData={(inputValue: string, optionLabel: ReactNode) => {
            if (typeof optionLabel === 'string') {
              return { name: optionLabel, id: parseInt(inputValue, 10) };
            }
            return { name: inputValue, id: parseInt(inputValue, 10) };
          }}
          isValidNewOption={(_inputValue, _value, options) => {
            return options.length === 0;
          }}
        />
      ) : (
        <AsyncSelect {...selectProps} />
      )}
    </>
  );
};

export default IndustriesSelect;
