import { useBoolean, useDisclosure } from '@chakra-ui/react';
import { StatusTypes } from '~/shared/enums/status';
import useEmployeeCertifications from '~/shared/hooks/employee/useEmployeeCertifications';
import {
  EmployeeCertification,
  UpdateEmployeeCertificationDates,
} from '~/shared/models/api/employee';
import { useEffect, useState } from 'react';

export interface UseVerifyCertificateModalType {
  certificateWasUpdated: boolean;
  isOpen: boolean;
  isUpdatingDate: boolean;
  isUpdatingStatus: boolean;
  isUploadingFile: boolean;
  onClose: () => void;
  onDateChange: (
    updatedDates: Partial<UpdateEmployeeCertificationDates>,
    onSuccess: () => void
  ) => void;
  onFileChange: (file: Blob, onSuccess: () => void) => void;
  onStatusChange: (status: StatusTypes) => void;
  openModal: (certificate: EmployeeCertification) => void;
  updateCertification?: EmployeeCertification;
}

const useVerifyCertificateModal = (): UseVerifyCertificateModalType => {
  const { isOpen, onOpen: onModalOpen, onClose } = useDisclosure();
  const [updateCertification, setUpdateCertification] =
    useState<EmployeeCertification>();
  const [certificateWasUpdated, setCertificateWasUpdated] = useBoolean();
  const {
    updateEmployeeCertificationStatus: {
      mutateAsync: updateCertificationStatus,
      isLoading: isUpdatingStatus,
    },
    uploadEmployeeCertificationFile: {
      mutateAsync: uploadCertificationFile,
      isLoading: isUploadingFile,
    },
    updateEmployeeCertificationDates: {
      mutateAsync: updateCertificationDates,
      isLoading: isUpdatingDate,
    },
  } = useEmployeeCertifications();

  const openModal = (certification: EmployeeCertification) => {
    setCertificateWasUpdated.off();
    setUpdateCertification(certification);
    onModalOpen();
  };

  useEffect(() => {
    if (isOpen) {
      return;
    }
    setUpdateCertification(undefined);
  }, [isOpen]);

  const onStatusChange = (status: StatusTypes) => {
    if (!updateCertification) {
      return;
    }

    updateCertificationStatus(
      {
        employee_id: updateCertification.employee_id,
        certificate_id: updateCertification.certificate_id,
        certificate_name: updateCertification.certificate.name,
        status,
      },
      {
        onSuccess: (certification: EmployeeCertification) => {
          setUpdateCertification(certification);
          setCertificateWasUpdated.on();
          onClose();
        },
      }
    );
  };

  const onFileChange = (file: Blob, onSuccess: () => void) => {
    if (!updateCertification) {
      return;
    }
    uploadCertificationFile(
      {
        new_certification_file: file,
        certificate_id: updateCertification.certificate_id,
        employee_id: updateCertification.employee_id,
        certificate_name: updateCertification.certificate.name,
      },
      {
        onSuccess: (certification: EmployeeCertification) => {
          setUpdateCertification(certification);
          setCertificateWasUpdated.on();
          onSuccess();
        },
      }
    );
  };

  const onDateChange = (
    updatedDates: Partial<UpdateEmployeeCertificationDates>,
    onSuccess: () => void
  ) => {
    if (!updateCertification) {
      return;
    }

    updateCertificationDates(
      {
        ...updatedDates,
        certificate_id: updateCertification.certificate_id,
        employee_id: updateCertification.employee_id,
        certificate_name: updateCertification.certificate.name,
      },
      {
        onSuccess: (certification: EmployeeCertification) => {
          setUpdateCertification(certification);
          setCertificateWasUpdated.on();
          onSuccess();
        },
      }
    );
  };

  return {
    certificateWasUpdated,
    isOpen,
    isUpdatingDate,
    isUpdatingStatus,
    isUploadingFile,
    onClose,
    onDateChange,
    onFileChange,
    onStatusChange,
    openModal,
    updateCertification,
  };
};

export default useVerifyCertificateModal;
