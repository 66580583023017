import { Box, Heading } from '@chakra-ui/react';
import ChildrenList, {
  ChildrenListProps,
} from '~/administration/components/projects/ChildrenList';
import ConfirmationModal from '~/shared/components/ui/ConfirmationModal';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

export interface ProjectMapConfirmationModalProps extends ChildrenListProps {
  isOpen: boolean;
  projectName?: string;
  onClose: () => void;
  onConfirm: () => void;
}

const ProjectMapConfirmationModal: FC<ProjectMapConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  isLoading,
  data,
  onRemove,
  projectName,
}) => {
  const { t } = useTranslation('projects');

  return (
    <ConfirmationModal
      titleText={t(`map_modal.confirmation.title`)}
      descriptionText={t(`map_modal.confirmation.description`)}
      confirmText={t('map_modal.confirmation.confirmation')}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
    >
      <Heading fontSize="sm" fontWeight="bold" mt="2" color="gray.500">
        {t(`map_modal.confirmation.parent_project`)}
      </Heading>
      <Heading fontSize="sm" fontWeight="bold" mt="2" color="gray.800" mb={2}>
        {projectName}
      </Heading>

      <Heading fontSize="sm" fontWeight="bold" mt="2">
        {t(`map_modal.children_title`)}
      </Heading>
      <Box
        w="100%"
        rounded={8}
        minH="50vh"
        maxH="50vh"
        bg="whiteSmoke"
        p={2}
        overflow="auto"
      >
        <ChildrenList
          isLoading={isLoading}
          data={data}
          onRemove={onRemove}
          canChange={false}
        />
      </Box>
    </ConfirmationModal>
  );
};

export default ProjectMapConfirmationModal;
