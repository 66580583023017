import { useDisclosure, UseDisclosureReturn, useToast } from '@chakra-ui/react';
import {
  ChildStatus,
  ChildStatusOrderValue,
} from '~/shared/enums/childStatusColor';
import { useSetProjectChildren } from '~/shared/hooks/project/useSetProjectChildren';
import { Project } from '~/shared/models/api/project';
import { useGetProjectChildren } from '~/shared/queries/useGetProjectChildren';
import { useGetProjectPotentialChildren } from '~/shared/queries/useGetProjectPotentialChildren';
import { t } from 'i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';

export interface Child {
  id: number;
  title: string;
}
export interface ChildRecord extends Child {
  status: ChildStatus;
  isNewRecord: boolean;
}

export interface UseProjectMapModal {
  children: ChildRecord[];
  confirmationModal: UseDisclosureReturn;
  isLoadingChildren: boolean;
  isLoadingParent: boolean;
  isSaving: boolean;
  hasChanges: boolean;
  handleAddChildClick: (child: Child) => void;
  handleCloseClick: () => void;
  handleConfirmationCancelClick: () => void;
  handleConfirmationSaveClick: () => Promise<void>;
  handleRemoveChildClick: (index: number) => void;
  handleSaveClick: () => Promise<void>;
  handleSearchTyping: (query: string) => void;
  potentialChildren: Child[];
  query?: string;
}

interface UseProjectMapModalProps {
  projectId?: number;
  onClose: () => void;
}
const useProjectMapModal = ({
  projectId,
  onClose,
}: UseProjectMapModalProps): UseProjectMapModal => {
  const [children, setChildren] = useState<ChildRecord[]>([]);
  const [potentialChildren, setPotentialChildren] = useState<Child[]>([]);
  const [query, setQuery] = useState<string>('');

  const confirmationModal = useDisclosure();

  const getChildren = useGetProjectChildren(projectId);

  const getPotentialChildren = useGetProjectPotentialChildren(projectId, query);

  const { setProjectChildren } = useSetProjectChildren();

  const toast = useToast();

  const handleRemoveChildClick = useCallback(
    (index: number) => {
      const child = children[index];

      if (child.isNewRecord) {
        setChildren((prev) => [...prev].filter((_, i) => i !== index));
        setPotentialChildren((prev) => [{ ...child }, ...prev]);

        return;
      }

      const isRemoved = child.status === ChildStatus.REMOVED;
      const newStatus = isRemoved ? ChildStatus.SAVED : ChildStatus.REMOVED;

      const newStatusChild = { ...child, status: newStatus };

      const newChildren = [
        newStatusChild,
        ...children.filter(({ id }) => id !== newStatusChild.id),
      ];
      const sortedChildren = newChildren.sort(
        (a, b) =>
          ChildStatusOrderValue[b.status] - ChildStatusOrderValue[a.status]
      );

      setChildren(sortedChildren);
    },
    [setChildren, setPotentialChildren, potentialChildren, children]
  );

  const handleAddChildClick = useCallback(
    (child: Child) => {
      const childRecord: ChildRecord = {
        ...child,
        isNewRecord: true,
        status: ChildStatus.ADDED,
      };

      setChildren((prev) => [childRecord, ...prev]);
      setPotentialChildren((prev) => prev.filter(({ id }) => id !== child.id));
    },
    [setChildren, setPotentialChildren, children, potentialChildren]
  );

  const hasChanges = useMemo(
    () => !!children.find(({ status }) => status !== ChildStatus.SAVED),
    [children]
  );

  const loadChildren = useCallback(() => {
    setChildren(
      getChildren.data?.length
        ? getChildren.data.map(({ id, title }) => ({
            id,
            title,
            isNewRecord: false,
            status: ChildStatus.SAVED,
          }))
        : []
    );
  }, [setChildren, getChildren.data]);

  const filterPotentialChildren = useCallback((): Project[] => {
    if (!getPotentialChildren.data?.length) {
      return [];
    }

    const childrenIds = new Set(children.map((child) => child.id));

    return getPotentialChildren.data.filter(
      (potentialChildren) => !childrenIds.has(potentialChildren.id)
    );
  }, [children, getPotentialChildren.data]);

  const loadPotentialChildren = useCallback(() => {
    const filtered = filterPotentialChildren();

    setPotentialChildren(
      filtered.map(({ id, title }) => ({
        id,
        title,
      }))
    );
  }, [
    setPotentialChildren,
    getPotentialChildren.data,
    filterPotentialChildren,
  ]);

  const resetStateAndClose = useCallback(() => {
    loadChildren();
    setQuery('');

    if (confirmationModal.isOpen) {
      confirmationModal.onClose();
    }

    onClose();
  }, [setChildren, setQuery, confirmationModal, onClose]);

  const getChildrenIdsPayload = useCallback(
    () =>
      children
        .filter(({ status: state }) => state !== ChildStatus.REMOVED)
        .map(({ id }) => id),
    [children]
  );

  const handleCloseClick = useCallback(() => {
    if (hasChanges) {
      confirmationModal.onOpen();
    } else {
      resetStateAndClose();
    }
  }, [confirmationModal, hasChanges, children]);

  const handleConfirmationCancelClick = () => {
    resetStateAndClose();
  };

  const handleSaveClick = useCallback(async () => {
    if (!projectId) {
      toast({
        title: t(`something_wrong`),
        description: t(`parent_id_missing`),
        status: 'error',
      });

      return;
    }

    await setProjectChildren.mutateAsync({
      projectId: projectId,
      childrenIds: getChildrenIdsPayload(),
    });

    getChildren.refetch();
    resetStateAndClose();
  }, [
    projectId,
    toast,
    setProjectChildren,
    getChildrenIdsPayload,
    getChildren,
    resetStateAndClose,
  ]);

  const handleConfirmationSaveClick = useCallback(async () => {
    await handleSaveClick();
    resetStateAndClose();
  }, [handleSaveClick, resetStateAndClose]);

  const handleSearchTyping = useCallback(
    (query: string) => {
      setQuery(query);
    },
    [setQuery]
  );

  useEffect(() => {
    if (getPotentialChildren.error) {
      toast({
        title: t(`something_wrong`),
        description: t(`potential_children_load_fail`),
        status: 'error',
      });
    }
  }, [getPotentialChildren.error]);

  useEffect(() => {
    if (getChildren.error) {
      toast({
        title: t(`something_wrong`),
        description: t(`project_children_load_fail`),
        status: 'error',
      });
    }
  }, [getChildren.error]);

  useEffect(() => {
    loadChildren();
  }, [getChildren.data]);

  useEffect(() => {
    loadPotentialChildren();
  }, [getPotentialChildren.data]);

  useEffect(() => {
    getChildren.refetch();
  }, []);

  return {
    children,
    confirmationModal,
    isLoadingChildren: getChildren.isFetching,
    isLoadingParent: getPotentialChildren.isFetching,
    isSaving: setProjectChildren.isLoading,
    hasChanges,
    handleCloseClick,
    handleConfirmationCancelClick,
    handleConfirmationSaveClick,
    handleSaveClick,
    handleAddChildClick,
    handleRemoveChildClick,
    handleSearchTyping,
    potentialChildren,
    query,
  };
};

export default useProjectMapModal;
