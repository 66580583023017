import { Badge, Box, Center, Link, SimpleGrid, Text } from '@chakra-ui/react';
import ApprovalButtons from '~/shared/components/approval-buttons/ApprovalButtons';
import EmployeeLink from '~/shared/components/EmployeeLink';
import Logo from '~/shared/components/Logo';
import StatusTag from '~/shared/components/status/StatusTag';
import { StatusTypes } from '~/shared/enums/status';
import { Certificate } from '~/shared/models/api/certificate';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface CertificateViewContentProps {
  certificate: Certificate;
  isUpdating: boolean;
  onClose: () => void;
  updateStatus: (newStatus: StatusTypes) => void;
}

const CertificateViewContent: FC<CertificateViewContentProps> = ({
  certificate,
  isUpdating,
  updateStatus,
}) => {
  const { t: tCertificates } = useTranslation('certificates');
  const { t: tBase } = useTranslation('base');

  const {
    name,
    logo,
    issuer,
    unique_identifier,
    certificate_url,
    status,
    permanent,
    created_by,
  } = certificate;

  return (
    <>
      <Center flexDirection="column">
        <Logo logo={logo} mb={3} />
        <Box mb={3}>
          {status && <StatusTag status={status} p={1} />}
          {permanent && (
            <Badge colorScheme="gray" letterSpacing="1px" p={1} ml={2}>
              {tCertificates('permanent')}
            </Badge>
          )}
        </Box>
      </Center>
      <SimpleGrid
        columns={2}
        mb={3}
        spacing={2}
        gridTemplateColumns="max-content 1fr"
      >
        <Text fontWeight="bold">{tBase('name')}:</Text>
        <Text>{name}</Text>
        <Text fontWeight="bold">{tCertificates('certificate.issuer')}:</Text>
        <Text>{issuer}</Text>
        <Text fontWeight="bold">
          {tCertificates('certificate.unique_identifier')}:
        </Text>
        <Text>{unique_identifier}</Text>
        {certificate_url && (
          <>
            <Text fontWeight="bold">
              {tCertificates('certificate.certification_url')}:
            </Text>
            <Text>
              <Link
                href={certificate_url}
                isExternal
                textDecoration="underline"
                color="blue.700"
              >
                {certificate_url}
              </Link>
            </Text>
          </>
        )}
        {created_by && (
          <>
            <Text fontWeight="bold">{tBase('created_by')}</Text>
            <EmployeeLink
              employeeId={created_by.id}
              textDecor="underline"
              color="blue.700"
            >
              {`${created_by.first_name} ${created_by.second_name}`}
            </EmployeeLink>
          </>
        )}
      </SimpleGrid>
      <Center my={5}>
        <ApprovalButtons
          onClick={updateStatus}
          status={status}
          showSpinner={isUpdating}
        />
      </Center>
    </>
  );
};

export default CertificateViewContent;
