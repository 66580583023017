import { ProfileSectionItemHeader } from '../ProfileSectionItemHeader';
import { StatusTypes, statusTypesColors } from '~/shared/enums/status';
import { EmployeeCertification } from '~/shared/models/api/employee';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface CertificationItemProps {
  certification: EmployeeCertification;
  onDelete: () => void;
}

const CertificationItem: React.FC<CertificationItemProps> = ({
  certification,
  onDelete,
}) => {
  const { t } = useTranslation('profile');

  const tagColorScheme = statusTypesColors[certification.status].substring(
    0,
    statusTypesColors[certification.status].indexOf('.')
  );
  const statusText =
    certification.status !== StatusTypes.APPROVED
      ? t(`base:status_types.${certification.status.toLowerCase()}`)
      : undefined;

  return (
    <ProfileSectionItemHeader
      onClickDelete={() => onDelete()}
      title={certification.certificate.name}
      startDate={certification.adquired_date}
      endDate={certification.expiring_date}
      dateIntervalTagTestId="dateIntervalTagTestId"
      endDateReplacementI18nKey="base:permanent"
      headerTestId="s"
      height={24}
      rightTag={statusText}
      tagColorScheme={tagColorScheme}
      logo={certification.certificate.logo}
      logoTestId="certification-logo"
      isMissingFile={!certification.certification_file}
      missingFileTooltipText={t('missing_certification_file')}
    />
  );
};
export default CertificationItem;
