import { Alert, AlertIcon, Box, Text } from '@chakra-ui/react';
import React from 'react';

type AlertStatus = 'error' | 'success' | 'warning' | 'info';

interface AlertInfoProps {
  text: string;
  status?: AlertStatus;
}

const AlertInfo: React.FC<AlertInfoProps> = ({ text, status }) => {
  return (
    <Box>
      <Alert
        status={status ? status : 'warning'}
        variant="left-accent"
        borderRadius="md"
      >
        <AlertIcon />
        <Text fontSize="md" color="gray.900" fontWeight="light">
          {text}
        </Text>
      </Alert>
    </Box>
  );
};

export default AlertInfo;
