import CustomSelect from '../select/CustomSelect';
import { StatusTypes } from '~/shared/enums/status';
import {
  mapObjectAsOption,
  mapObjectListAsOptions,
} from '~/shared/mappers/selectOption';
import { SelectOption } from '~/shared/models/react-select/select-option';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface StatusSelectProps {
  id?: string;
  selectedStatus?: StatusTypes;
  isUpdating?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  isClearable?: boolean;
  onChange?: (value?: StatusTypes) => void;
}

const StatusSelect: FC<StatusSelectProps> = ({
  id = 'status-select',
  selectedStatus,
  isUpdating,
  placeholder,
  isDisabled,
  isClearable,
  onChange,
}) => {
  const { t } = useTranslation('base');
  const [selectedOption, setSelectedOption] =
    useState<SelectOption<StatusTypes> | null>(null);

  useEffect(() => {
    if (!selectedStatus) {
      setSelectedOption(null);
      return;
    }
    setSelectedOption(
      mapObjectAsOption(
        StatusTypes,
        selectedStatus
      ) as SelectOption<StatusTypes>
    );
  }, [selectedStatus]);

  const options: SelectOption<StatusTypes>[] = useMemo(
    () => mapObjectListAsOptions(StatusTypes) as SelectOption<StatusTypes>[],
    []
  );

  return (
    <CustomSelect<StatusTypes>
      id={id}
      placeholder={placeholder || t('select')}
      isClearable={isClearable}
      isLoading={isUpdating}
      isDisabled={isDisabled}
      isSearchable={false}
      selectedOption={selectedOption}
      options={options}
      getOptionLabel={(option) =>
        t(`status_types.${option.label.toLowerCase()}`)
      }
      onChange={onChange}
    />
  );
};

export default StatusSelect;
