import { PPTLayouts } from '../../../../profile/components/export/ppt/enums';
import { Box, useDisclosure } from '@chakra-ui/react';
import { externalUrls } from '~/authentication/config';
import ExportModal from '~/profile/components/export/ExportModal';
import pptExport from '~/profile/components/export/pptExport';
import useProfileStore from '~/profile/stores/profile';
import { MainRoutesPaths } from '~/routes/enums/main';
import RoundedButton from '~/shared/components/buttons/RoundedButton';
import EmployeeAvatar from '~/shared/components/EmployeeAvatar';
import useAccessPermissions from '~/shared/hooks/permissions/useAccessPermissions';
import { Employee } from '~/shared/models/api/employee';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RiFilePdfFill,
  RiFilePpt2Fill,
  RiLogoutCircleRLine,
  RiSurveyLine,
  RiUserSettingsFill,
} from 'react-icons/ri';

interface UserMenuProps {
  loggedProfile: Employee | null;
  isLoading: boolean;
  handleLogoutClick: () => void;
}

const UserMenu: FC<UserMenuProps> = ({
  isLoading,
  handleLogoutClick,
  loggedProfile,
}) => {
  const { t } = useTranslation(['base', 'admin', 'profile']);
  const { isAdmin } = useAccessPermissions();
  const { profile } = useProfileStore();
  const { canExportProfile } = useAccessPermissions();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      {canExportProfile && profile && (
        <>
          <ExportModal isOpen={isOpen} onClose={onClose} />
          <RoundedButton
            tooltipLabel={t(`profile:export.ppt_layout.two_profiles_per_page`)}
            type="submit"
            colorScheme="orange"
            onClick={
              () =>
                pptExport(PPTLayouts.TWO_PROFILES_PER_PAGE, [profile, profile]) // TODO : this is only to show the PPT layout for validation, later an interface will be created to select multiple profiles
            }
            isLoading={isLoading}
          >
            <RiFilePpt2Fill size={22} opacity={0.8} />
          </RoundedButton>
          <RoundedButton
            tooltipLabel={t(`profile:export.ppt_layout.ki_group`, {
              name: profile.first_name,
            })}
            type="submit"
            onClick={() =>
              pptExport(PPTLayouts.ONE_PROFILE_PER_PAGE, [profile])
            }
            isLoading={isLoading}
          >
            <RiFilePpt2Fill size={22} opacity={0.8} />
          </RoundedButton>
          <RoundedButton
            tooltipLabel={t(`profile:export.pdf`, { name: profile.first_name })}
            type="submit"
            onClick={onOpen}
            isLoading={isLoading}
          >
            <RiFilePdfFill size={22} opacity={0.8} />
          </RoundedButton>
        </>
      )}

      {externalUrls.feedbackSurvey && (
        <RoundedButton
          linkTo={externalUrls.feedbackSurvey}
          tooltipLabel={t(`base:feedback_survey`)}
          isLoading={isLoading}
        >
          <RiSurveyLine size={24} opacity={0.8} />
        </RoundedButton>
      )}
      {isAdmin && (
        <RoundedButton
          linkTo={MainRoutesPaths.ADMINISTER}
          tooltipLabel={t(`admin:admin`)}
          isLoading={isLoading}
        >
          <RiUserSettingsFill size={24} opacity={0.8} />
        </RoundedButton>
      )}

      <RoundedButton
        tooltipLabel={t(`profile:my_profile`)}
        linkTo={MainRoutesPaths.PROFILE}
        isLoading={isLoading}
      >
        <EmployeeAvatar imagePath={loggedProfile?.image_url} size="sm" />
      </RoundedButton>
      <RoundedButton
        onClick={handleLogoutClick}
        isLoading={isLoading}
        tooltipLabel={t(`base:logout`)}
      >
        <RiLogoutCircleRLine size={22} opacity={0.8} />
      </RoundedButton>
    </Box>
  );
};

export default UserMenu;
