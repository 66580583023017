import {
  Box,
  Button,
  Grid,
  GridItem,
  ModalBody,
  ModalFooter,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  defaultNewExternalExperiencesSchema,
  ExternalExperiencesFormType,
  mapExternalExperiences,
  newExternalExperiencesSchema,
} from '~/profile/forms/externalExperiences';
import useProfileStore from '~/profile/stores/profile';
import FormControl from '~/shared/components/form/FormControl';
import FormControlDatePicker from '~/shared/components/form/FormControlDatePicker';
import FormControlInput from '~/shared/components/form/FormControlInput';
import FormControlRichText from '~/shared/components/form/FormControlRichText';
import FormControlSelect from '~/shared/components/form/FormControlSelect';
import SkillSelectByValue from '~/shared/components/select/skill/SkillSelectByValue';
import { DateType } from '~/shared/enums/date';
import {
  experiencesTranslation,
  ExternalExperiences,
} from '~/shared/enums/externalExperiences';
import { EmployeeExternalExperience } from '~/shared/models/api/employee';
import React, { useEffect } from 'react';
import {
  FieldNamesMarkedBoolean,
  FormProvider,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface ExternalExperiencesFormProps {
  project?: EmployeeExternalExperience;
  onSubmit: (project: EmployeeExternalExperience) => void;
  onCancel: () => void;
  onChange: (
    dirtyFields: Partial<
      Readonly<FieldNamesMarkedBoolean<EmployeeExternalExperience>>
    >
  ) => void;
}

const ExternalExperiencesForm: React.FC<ExternalExperiencesFormProps> = ({
  project,
  onSubmit,
  onChange,
  onCancel,
}) => {
  const { profile } = useProfileStore();
  const methods = useForm({
    resolver: yupResolver(newExternalExperiencesSchema),
    defaultValues: defaultNewExternalExperiencesSchema(profile, project),
  });
  const {
    handleSubmit,
    register,
    watch,
    reset,
    control,
    setValue,
    formState: { errors, dirtyFields },
  } = methods;

  const startAt = watch('start_date');
  const endAt = watch('end_date');
  const technologies = watch('technologies');
  const today = new Date();
  const formValues = watch();

  useEffect(() => {
    onChange(dirtyFields);
  }, [formValues, dirtyFields]);

  useEffect(() => {
    reset();
  }, []);

  const { t } = useTranslation(['base', 'profile', 'skills']);

  const handleFormSubmit = (values: ExternalExperiencesFormType) => {
    const experience: EmployeeExternalExperience =
      mapExternalExperiences(values);

    onSubmit(experience);
  };

  return (
    <FormProvider {...methods}>
      <ModalBody>
        <Text fontSize="md" color="gray.600" fontWeight="bold" mb="2">
          {project
            ? t(`profile:experiences.external.form.edit`)
            : t(`profile:experiences.external.add`)}
        </Text>
        <Grid templateColumns="repeat(2, 1fr)" gap="2" mt="4">
          <FormControlSelect
            id="type"
            name={t(`profile:experiences.external.form.fields.type`)}
            placeholder={t(`profile:experiences.external.form.fields.type`)}
            register={register}
            error={errors.type}
          >
            {Object.values(ExternalExperiences).map((value) => (
              <option key={value} value={value}>
                {t(
                  `profile:experiences.external.types.${experiencesTranslation[value]}`
                )}
              </option>
            ))}
          </FormControlSelect>

          <GridItem colStart={1}>
            <FormControlInput
              id="title"
              name={t(`profile:experiences.external.form.fields.title`)}
              register={register}
              placeholder={t(`profile:experiences.external.form.fields.title`)}
              error={errors.title}
              maxLength={50}
            />
          </GridItem>
          <FormControlInput
            id="company"
            name={t(`base:company`)}
            register={register}
            placeholder={t(`base:company_place_holder`)}
            error={errors.company}
            maxLength={50}
          />
          <GridItem colSpan={2}>
            <FormControlRichText
              id="description"
              name={t(`base:description`)}
              value={watch('description')}
              register={register}
              setValue={setValue}
              placeholder={t(
                `profile:experiences.external.form.fields.description_place_holder`
              )}
              error={errors.description}
            />
          </GridItem>
          <FormControlDatePicker
            id="start_date"
            name={t(`base:date.start_date`)}
            register={register}
            setValue={setValue}
            error={errors.start_date}
            selectedDate={startAt ? new Date(startAt) : null}
            maxDate={endAt ? new Date(endAt) : today}
            chosenDateType={DateType.YEAR_MONTH}
          />
          <FormControlDatePicker
            id="end_date"
            name={t(`base:date.end_date`)}
            register={register}
            error={errors.end_date}
            setValue={setValue}
            selectedDate={endAt ? new Date(endAt) : null}
            disabled={startAt == undefined}
            minDate={startAt ? new Date(startAt) : undefined}
            maxDate={today}
            chosenDateType={DateType.YEAR_MONTH}
          />
        </Grid>
        <Box mt="2">
          <FormControl
            id="technologies"
            label={t(`skills:title`)}
            control={control}
            error={errors.technologies}
          >
            <SkillSelectByValue selectedSkills={technologies} isMulti />
          </FormControl>
        </Box>
      </ModalBody>
      <ModalFooter justifyContent="space-between">
        <Button
          data-testid="modal-external-experiences-close-button"
          type="button"
          mr={2}
          onClick={onCancel}
        >
          {t(`base:close`)}
        </Button>
        <Button
          colorScheme="blue"
          type="submit"
          variant="solid"
          mr={4}
          data-testid="modal-external-experiences-save-button"
          onClick={handleSubmit(handleFormSubmit)}
        >
          {project ? t(`base:update`) : t(`base:save`)}
        </Button>
      </ModalFooter>
    </FormProvider>
  );
};

export default ExternalExperiencesForm;
