import {
  Badge,
  Box,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  VStack,
} from '@chakra-ui/react';
import ChildrenList from '~/administration/components/projects/ChildrenList';
import PotentialChildrenList from '~/administration/components/projects/PotentialChildrenList';
import ProjectMapConfirmationModal from '~/administration/components/projects/ProjectMapConfirmationModal';
import useProjectMapModal from '~/administration/hooks/projects/useProjectMapModal';
import SearchInput from '~/shared/components/SearchInput';
import AlertInfo from '~/shared/components/ui/AlertInfo';
import ModalButton from '~/shared/components/ui/ModalButton';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ProjectMapModalProps {
  title?: string;
  projectId?: number;
  isOpen: boolean;
  onClose: () => void;
}

const ProjectMapModal: FC<ProjectMapModalProps> = ({
  title,
  projectId,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation('projects');
  const {
    children,
    confirmationModal,
    isLoadingChildren,
    isLoadingParent,
    isSaving,
    hasChanges,
    handleAddChildClick,
    handleCloseClick,
    handleConfirmationCancelClick,
    handleConfirmationSaveClick,
    handleRemoveChildClick,
    handleSaveClick,
    handleSearchTyping: handleSearchType,
    potentialChildren,
    query,
  } = useProjectMapModal({ projectId, onClose });

  return (
    <Modal isOpen={isOpen} onClose={handleCloseClick} size="3xl">
      <ModalOverlay />
      <ProjectMapConfirmationModal
        isOpen={confirmationModal.isOpen}
        onClose={handleConfirmationCancelClick}
        onConfirm={handleConfirmationSaveClick}
        isLoading={isLoadingChildren}
        data={children}
        onRemove={handleRemoveChildClick}
        projectName={title}
      />
      <ModalContent>
        <Stack direction="column">
          <ModalHeader mt="2" mb="0">
            <Badge colorScheme="blue" px={2}>
              {t(`map_modal.label`)}
            </Badge>
            <Heading fontSize="lg" fontWeight="bold" mt="2" color="gray.500">
              {t(`map_modal.title`)}
            </Heading>
            <Heading fontSize="md" fontWeight="bold" mt="2" color="gray.800">
              {title}
            </Heading>
          </ModalHeader>
        </Stack>
        <ModalCloseButton />
        <ModalBody>
          <HStack justifyContent="center" gap={5} p={1}>
            <Stack minW="45%" maxW="45%">
              <Heading fontSize="sm" fontWeight="bold" mt="2">
                {t(`map_modal.potential_children_title`)}
              </Heading>
              <VStack minH="50vh" maxH="50vh" h="fit-content" rounded={8}>
                <Stack minW="full" maxW="full" h="6vh" bg="white" pb={2}>
                  <SearchInput
                    query={query}
                    onChange={handleSearchType}
                    w="100%"
                    bg="white"
                    rounded={8}
                    shadow="sm"
                  />
                </Stack>
                <Stack
                  minW="full"
                  maxW="full"
                  minH="44vh"
                  maxH="44vh"
                  bg="whiteSmoke"
                  rounded={8}
                >
                  <VStack p={2} overflow="scroll" alignItems="initial">
                    {(!query?.length || query.length < 3) && (
                      <Stack>
                        <AlertInfo
                          text={t(
                            `map_modal.${
                              !query?.length ? 'type_to_search' : 'type_more'
                            }`
                          )}
                          status={!query?.length ? 'warning' : 'success'}
                        />
                      </Stack>
                    )}
                    {query?.length && query.length >= 3 && (
                      <PotentialChildrenList
                        isLoading={isLoadingParent}
                        data={potentialChildren}
                        onAdd={handleAddChildClick}
                      />
                    )}
                  </VStack>
                </Stack>
              </VStack>
            </Stack>
            <Stack minW="45%" maxW="45%">
              <Heading fontSize="sm" fontWeight="bold" mt="2">
                {t(`map_modal.children_title`)}
              </Heading>
              <Box
                w="100%"
                rounded={8}
                minH="50vh"
                maxH="50vh"
                bg="whiteSmoke"
                p={2}
                overflow="auto"
              >
                <ChildrenList
                  isLoading={isLoadingChildren}
                  data={children}
                  onRemove={handleRemoveChildClick}
                />
              </Box>
            </Stack>
          </HStack>
        </ModalBody>
        <ModalFooter justifyContent="end" gap={5}>
          <ModalButton type="button" onClick={handleCloseClick}>
            {t(`map_modal.button.cancel`)}
          </ModalButton>
          <ModalButton
            colorScheme="blue"
            type="submit"
            variant="solid"
            onClick={handleSaveClick}
            disabled={!hasChanges}
            isUpdating={isSaving}
          >
            {t(`map_modal.button.save`)}
          </ModalButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ProjectMapModal;
