import { Flex, ThemeTypings } from '@chakra-ui/react';
import { ProfileSectionItemIntervalAndTag } from '~/profile/components/ProfileSectionItemIntervalAndTag';
import { ProfileSectionItemTitleAndSubTitle } from '~/profile/components/ProfileSectionItemTitleAndSubTitle';
import { ActionButtons } from '~/profile/components/shared/ActionButtons';
import Logo from '~/shared/components/Logo';
import MissingFileIcon from '~/shared/components/ui/MissingFileIcon';
import useAccessPermissions from '~/shared/hooks/permissions/useAccessPermissions';
import React from 'react';
import { CiFileOn } from 'react-icons/ci';

interface ProfileSectionItemHeaderProps {
  onClickEdit?: () => void;
  onClickDelete: () => void;
  title: string;
  atTitle?: string;
  rightTag?: string;
  startDate: string;
  endDate?: string | null;
  headerTestId?: string;
  height?: number;
  dateIntervalTagTestId?: string;
  tagColorScheme?: ThemeTypings['colorSchemes'];
  endDateReplacementI18nKey?: string;
  logo?: string;
  logoTestId?: string;
  mb?: number;
  isMissingFile?: boolean;
  missingFileTooltipText?: string;
}

export const ProfileSectionItemHeader: React.FC<
  ProfileSectionItemHeaderProps
> = ({
  onClickEdit,
  onClickDelete,
  title,
  atTitle,
  rightTag,
  startDate,
  endDate,
  headerTestId,
  height,
  dateIntervalTagTestId,
  tagColorScheme,
  endDateReplacementI18nKey,
  logo,
  logoTestId,
  isMissingFile,
  missingFileTooltipText,
}) => {
  const { canEditProfile } = useAccessPermissions();

  return (
    <Flex
      alignContent="center"
      alignItems="center"
      backgroundColor="gray.50"
      borderColor="gray.100"
      borderRadius={25}
      borderWidth={1}
      justifyContent="space-between"
      height={height}
      ml={2}
      my={1}
      pl={4}
      pr={2}
      py={1}
    >
      <Flex alignContent="center" alignItems="center">
        {logo && (
          <Logo
            data-testid={logoTestId}
            logo={logo}
            noImageIconSize={50}
            size="lg"
            mr="3"
            my={2}
            rounded={25}
          />
        )}
        <ProfileSectionItemTitleAndSubTitle
          headingTestId={headerTestId}
          title={title}
          atTitle={atTitle}
        />
        {isMissingFile && (
          <MissingFileIcon
            tooltip={missingFileTooltipText}
            as={CiFileOn}
            boxSize={5}
          />
        )}
      </Flex>

      <Flex>
        <ProfileSectionItemIntervalAndTag
          dateIntervalTagTestId={dateIntervalTagTestId}
          startDate={startDate}
          endDate={endDate}
          sideTagText={rightTag}
          tagColorScheme={tagColorScheme}
          endDateReplacementI18nKey={endDateReplacementI18nKey}
        />
        <ActionButtons
          onClickEdit={onClickEdit}
          onClickDelete={onClickDelete}
          hasPermissions={canEditProfile}
          tooltipLabel={title}
        />
      </Flex>
    </Flex>
  );
};
