export enum PlaceholderKeys {
  description = 'description',
  jobTitle = 'jobTitle',
  nameSurname = 'nameSurname',
  profilePicture = 'profilePicture',
  skills = 'skills',
  title = 'title',
}

export enum PPTLayouts {
  ONE_PROFILE_PER_PAGE = 'oneProfilePerPage',
  TWO_PROFILES_PER_PAGE = 'twoProfilesPerPage',
}
