import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export const formatDate = (
  date: dayjs.ConfigType,
  format = 'YYYY-MM',
  defaultValue = ''
) => {
  return date ? dayjs(date).format(format.toUpperCase()) : defaultValue;
};

export const newDate = (format: string) => {
  return formatDate(dayjs(), format);
};
