import { adminPermissions } from '../../constants/permissions';
import { StatusTypes } from '../../enums/status';
import { hasPermissionBuilder } from './helper/hasEmployeePermissionBuilder';
import useProfileStore from '~/profile/stores/profile';
import { Permission } from '~/shared/enums/permissions';
import { useCallback } from 'react';

interface UseAccessPermissionsType {
  canEditProfile: boolean;
  canEditProfileAdmOnly: boolean;
  canExportProfile: boolean;
  canSeeCertification(status: StatusTypes): boolean;
  canSeeVerificationState: boolean;
  canVerifyAllProfiles: boolean;
  canVerifyTeamProfiles: boolean;
  hasPermission: (permissionNames: Permission[]) => boolean;
  isAdmin: boolean;
}

const useAccessPermissions = (): UseAccessPermissionsType => {
  const { myProfile, isSelf } = useProfileStore();

  const hasPermission = useCallback(
    hasPermissionBuilder(myProfile?.permissions),
    [myProfile?.permissions]
  );

  return {
    canEditProfile: isSelf || hasPermission([Permission.EDIT_PROFILES]),

    canEditProfileAdmOnly: hasPermission([Permission.EDIT_PROFILES]),

    canExportProfile: isSelf || hasPermission([Permission.EXPORT_PROFILES]),

    canSeeCertification: (status: StatusTypes) =>
      isSelf ||
      status === StatusTypes.APPROVED ||
      hasPermission([Permission.VERIFY_CERTIFICATES]),

    canSeeVerificationState:
      isSelf || hasPermission([Permission.SEE_VERIFICATION_STATE]),

    hasPermission,

    canVerifyAllProfiles: hasPermission([Permission.VERIFY_TEAM_PROFILES]),

    canVerifyTeamProfiles: hasPermission([Permission.VERIFY_ALL_PROFILES]),

    isAdmin: hasPermission(adminPermissions),
  };
};

export default useAccessPermissions;
