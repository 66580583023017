import { ProfileSectionItemHeader } from '../../ProfileSectionItemHeader';
import { ExperienceSkills } from '../components/ExperienceSkills';
import { InternalExperienceItemDetail } from './InternalExperienceItemDetail';
import { Box, Flex, Heading } from '@chakra-ui/react';
import { EmployeeInternalExperience } from '~/shared/models/api/employee';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface InternalExperienceItemProps {
  experience: EmployeeInternalExperience;
  onOpen: (project: EmployeeInternalExperience) => void;
  onDelete: () => void;
}

const InternalExperienceItem: React.FC<InternalExperienceItemProps> = ({
  experience,
  onOpen,
  onDelete,
}) => {
  const { t } = useTranslation(['base', 'projects']);
  const isInternal = !experience.project.external;

  return (
    <Box>
      <ProfileSectionItemHeader
        onClickEdit={() => onOpen(experience)}
        onClickDelete={() => onDelete()}
        title={experience.project.sms_title}
        startDate={experience.start_at}
        endDate={experience.end_at}
        headerTestId={`internal-experiences-item-heading-${experience.id}`}
        dateIntervalTagTestId={`internal-experiences-item-badge-${experience.id}`}
        rightTag={isInternal ? t(`projects:internal`) : t(`projects:external`)}
        tagColorScheme={isInternal ? 'yellow' : 'orange'}
      />

      <Flex direction="column" ml={6}>
        <Heading as="h4" fontSize="md" my={2}>
          {experience.role}
        </Heading>

        <Flex gap="2%" mb={2}>
          <InternalExperienceItemDetail
            title={t(`projects:headers.description`)}
            text={experience.project.description}
          />

          <InternalExperienceItemDetail
            title={t(`projects:headers.tasks`)}
            text={experience.tasks}
          />
        </Flex>

        <ExperienceSkills
          skills={experience.technologies}
          skillsKey={`external-experiences-skills-${experience.project.id}`}
        />
      </Flex>
    </Box>
  );
};

export default InternalExperienceItem;
