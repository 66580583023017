import {
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Link,
  ModalBody,
  ModalFooter,
  Stack,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import ActionButton from '~/profile/components/shared/ActionButton';
import {
  CertificationFormType,
  mapCertification,
  newCertificationSchema,
} from '~/profile/forms/certification';
import useProfileStore from '~/profile/stores/profile';
import FileUploadInput from '~/shared/components/FileUploadInput';
import FormControl from '~/shared/components/form/FormControl';
import FormControlDatePicker from '~/shared/components/form/FormControlDatePicker';
import CertificateSelect from '~/shared/components/select/CertificateSelect';
import StatusTag from '~/shared/components/status/StatusTag';
import { certificationFileType } from '~/shared/constants/certifications';
import { ButtonTypes } from '~/shared/enums/actionButton';
import { DateType } from '~/shared/enums/date';
import { StatusTypes } from '~/shared/enums/status';
import useAccessPermissions from '~/shared/hooks/permissions/useAccessPermissions';
import { Certificate } from '~/shared/models/api/certificate';
import { CreateEmployeeCertification } from '~/shared/models/api/employee';
import { fileToDataURL } from '~/shared/utils/fileUpload';
import React, { useEffect } from 'react';
import {
  FieldNamesMarkedBoolean,
  FormProvider,
  useForm,
} from 'react-hook-form';
import { FaFileUpload } from 'react-icons/fa';

interface CertificationFormProps {
  onSubmit: (certification: CreateEmployeeCertification) => void;
  onCancel: () => void;
  onChange: (
    dirtyFields: Partial<
      Readonly<FieldNamesMarkedBoolean<CreateEmployeeCertification>>
    >
  ) => void;
  onCertificateChange: (certificate?: Certificate) => void;
}

const CertificationForm: React.FC<CertificationFormProps> = ({
  onSubmit,
  onChange,
  onCertificateChange,
  onCancel,
}) => {
  const { profile } = useProfileStore();
  const methods = useForm({
    resolver: yupResolver(newCertificationSchema),
  });
  const {
    handleSubmit,
    register,
    watch,
    reset,
    control,
    setValue,
    formState: { errors, dirtyFields },
  } = methods;

  const expiringDate = watch('expiring_date');
  const issuedOnDate = watch('adquired_date');
  const certificate = watch('certificate');
  const certificate_file = watch('certificate_file');
  const certificate_url = watch('certificate_url');
  const formValues = watch();
  const { canEditProfile } = useAccessPermissions();

  useEffect(() => {
    onChange(dirtyFields);
  }, [formValues, dirtyFields]);

  useEffect(() => {
    if (certificate) {
      return;
    }
    reset();
  }, [certificate]);

  useEffect(() => {
    if (!certificate_file) {
      setValue('certificate_url', null);
      return;
    }

    const setCertificateUrl = async () => {
      const url = await fileToDataURL(certificate_file);
      setValue('certificate_url', url as string);
    };

    setCertificateUrl();
  }, [certificate_file]);

  const handleFormSubmit = ({
    certificate,
    ...values
  }: CertificationFormType) => {
    if (!certificate || !profile) {
      return;
    }
    const updatedCertificate = mapCertification({
      certificate,
      employee_id: profile.id,
      ...values,
    });
    onSubmit(updatedCertificate);
  };

  return (
    <>
      <ModalBody>
        <Stack>
          <Box>
            <Text
              data-testid="test-heading-title"
              fontSize="md"
              color="gray.600"
              fontWeight="bold"
            >
              Add New Certification
            </Text>
            <Text fontSize="sm" mb="2">
              *You won&apos;t be able to make any changes after creating it.
            </Text>
          </Box>
          <Grid templateColumns="repeat(1, 1fr)" gap="2" mt="4">
            <FormProvider {...methods}>
              <Box>
                <FormControl
                  id="certificate"
                  label="Certificate"
                  control={control}
                  error={errors.certificate}
                >
                  <CertificateSelect
                    isCreatable
                    onChange={onCertificateChange}
                  />
                </FormControl>
                {certificate?.permanent && (
                  <Badge colorScheme="cyan" mb="2">
                    Permanent
                  </Badge>
                )}
              </Box>
              <Box minH={28}>
                {certificate && (
                  <>
                    {certificate.status === StatusTypes.PENDING && (
                      <StatusTag status={StatusTypes.PENDING} mb={3}>
                        Pending approval
                      </StatusTag>
                    )}

                    {certificate.status === StatusTypes.APPROVED ? (
                      <>
                        <Flex gridGap={3} mb={3}>
                          <FormControlDatePicker
                            id="adquired_date"
                            name="Issued date"
                            register={register}
                            selectedDate={
                              issuedOnDate ? new Date(issuedOnDate) : null
                            }
                            maxDate={
                              expiringDate ? new Date(expiringDate) : undefined
                            }
                            error={errors.adquired_date}
                            chosenDateType={DateType.YEAR_MONTH}
                          />
                          {!certificate.permanent && (
                            <FormControlDatePicker
                              id="expiring_date"
                              name="Expiration date"
                              register={register}
                              selectedDate={
                                expiringDate ? new Date(expiringDate) : null
                              }
                              minDate={
                                issuedOnDate
                                  ? new Date(issuedOnDate)
                                  : undefined
                              }
                              error={errors.expiring_date}
                              chosenDateType={DateType.YEAR_MONTH}
                            />
                          )}
                        </Flex>
                        <Box mb={5}>
                          <FormControl
                            id="certificate_file"
                            control={control}
                            error={errors.certificate_file}
                          >
                            <FileUploadInput
                              id="certificate-file-upload"
                              accept={certificationFileType}
                              showAcceptFormats
                            >
                              <Button
                                type="button"
                                variant="ghost"
                                size="sm"
                                mt={2}
                                pl={0}
                              >
                                <Icon as={FaFileUpload} fontSize={32} mr={2} />
                                <Text>
                                  {certificate_url
                                    ? 'Replace Certificate'
                                    : 'Attach Certificate'}
                                </Text>
                              </Button>
                            </FileUploadInput>
                          </FormControl>
                          {certificate_url && (
                            <Flex>
                              <Link
                                display="flex"
                                alignItems="center"
                                href={certificate_url}
                                download="certificate.pdf"
                              >
                                {certificate_file?.name}
                              </Link>
                              <ActionButton
                                type={ButtonTypes.Delete}
                                hasPermission={canEditProfile}
                                onClick={() =>
                                  setValue('certificate_file', null)
                                }
                              />
                            </Flex>
                          )}
                        </Box>
                      </>
                    ) : (
                      <Text mt={3}>Please come back later</Text>
                    )}
                  </>
                )}
              </Box>
            </FormProvider>
          </Grid>
        </Stack>
      </ModalBody>

      <ModalFooter justifyContent="space-between">
        <Button
          data-testid="modal-certification-cancel-button"
          type="button"
          mr={2}
          onClick={onCancel}
        >
          Close
        </Button>
        {certificate?.status === StatusTypes.APPROVED && (
          <Button
            colorScheme="blue"
            type="submit"
            variant="solid"
            mr={4}
            data-testid="form-certification-submit-button"
            onClick={handleSubmit(handleFormSubmit)}
          >
            Create
          </Button>
        )}
      </ModalFooter>
    </>
  );
};

export default CertificationForm;
